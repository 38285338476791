import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { ResearchComponentsCoreModule  } from '@corteva-research/ngx-components-core';
import { MsalService, MsalGuard, MsalBroadcastService,MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';

import { AgGridModule } from 'ag-grid-angular';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import { GridPerfComponent } from './components/gridPerf/gridPerf.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { AdminComponent } from './components/admin/admin.component';
import { ManageprogramComponent } from './components/manageprogram/manageprogram.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { UnderconstructionComponent } from './components/underconstruction/underconstruction.component';
import { GeassessmentmodalComponent } from './components/geassessmentmodal/geassessmentmodal.component';
import { ImportFileComponent } from './components/import-file/import-file.component';
import { CodeComponent } from './components/code/code.component';
import { LinegraphComponent } from './components/linegraph/linegraph.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';
import { CommonreportmodalComponent } from './components/commonreportmodal/commonreportmodal.component';
import { ManagecropComponent } from './components/managecrop/managecrop.component';

import { SharedModule } from './shared/modules/shared.module';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    PagenotfoundComponent,
    GridPerfComponent,
    AdminComponent,
    ManageprogramComponent,
    PreferencesComponent,
    GeassessmentmodalComponent,
    UnderconstructionComponent,
    ImportFileComponent,
    CodeComponent,
    LinegraphComponent,
    CommonreportmodalComponent,
    ManagecropComponent,
    UnAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    ClipboardModule,
    TypeaheadModule.forRoot(),
    ResearchComponentsCoreModule.forRoot('precodemanager-ui') ,
    ResearchComponentsMsalIntegrationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      //useClass: Interceptor,
      useClass: MsalInterceptor,
      multi: true
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
