
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component,ChangeDetectorRef,AfterContentChecked } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Component({
  selector:'prime-autocomplete',
  template : `<p-autoComplete  [suggestions]="aois" [(ngModel)]="selectedAois" (completeMethod)="search($event)" optionLabel="name" multiple="true"   (ngModelChange)="changeAois($event)" [baseZIndex]="99999"
  multiple="true" appendTo="body"
  [style]="{'width':'452px','height' : '71px'}"></p-autoComplete >`,
  styles:[
    `:host ::ng-deep .p-icon-wrapper {
      pointer-events: unset !important;
      }
      
      :host ::ng-deep .p-autocomplete .p-autocomplete-multiple-container{
        width: 333px;
        overflow-y: scroll;
      }
      `
  ]

})
export class AutoCompleteComponent implements ICellRendererAngularComp,AfterContentChecked   {
  params!: any;
  aois : any;
  selectedAois : any[] = [];
  enums: { label: any; value: any; }[] = [];

  constructor(private changeDetector : ChangeDetectorRef) {
    
  }

  agInit(params: any): void {
    this.params = params;
    if(this.params.data?.aoiNameList && this.params.data?.aoiNameList?.length > 0) {
      this.selectedAois = this.params.data?.aoiNameList;
    }
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
  }

  search(event : any) {
     if (event.query.length > 1) {
      let params = new HttpParams();
      params= params.append('aoiName',event.query)
      this.params.context.componentParent.apiService.getData("GetAoiNames", params).subscribe((result : any) => {
        this.aois = result.data;
      });
    }

  }

  

  changeAois(event : any) {
    const index = this.params.context.componentParent.rowData.findIndex((ele : any) => ele.traitId == this.params.data.traitId);
    this.selectedAois = this.selectedAois.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.name === value.name 
    )));
    this.params.data['aoiNameList'] = this.params.context.componentParent.rowData[index]['aoiNameList'] = this.selectedAois;
    this.params.data['aois'] = this.params.data['aoiNameList'].map((ele : any) => ele.name ).join(); 
  }


  refresh() {
    return true;
  }

  getValue() {
    return this.params.value;
  }


  doSomething() {}
}
