import { HttpParams } from '@angular/common/http';
import { Component, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, StatusPanelDef, GridOptions, IMultiFilterParams, GridPreDestroyedEvent, GridReadyEvent } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Crop } from 'src/app/models/crop';
import { GridPreference } from 'src/app/models/gridPreference';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { ReportModalHeaderComponent } from 'src/app/shared/reportModalHeader';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-commonreportmodal',
  templateUrl: './commonreportmodal.component.html',
  styleUrl: './commonreportmodal.component.scss'
})
export class CommonreportmodalComponent {
  selectedCrop : any;
  disableCrop : boolean = true;
  collapseSearch: boolean = false;
  collapseResults: boolean = true;
  assessmentMembersList: any[]= [];
  assessmentMembers: any[] = [];
  searchType: string = "Get All Assessments";
  searchBy: string = "GE ID";
  selectType: string = "Show all Assessments";
  showSelected : boolean = false
  selectedMembers: any;
  enableSearch : boolean = false;
  rowData : any[] =[];
  idList: string[] = [];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth : 50, 
    resizable: true,
  };
  public columnDefs: ColDef[] = [
   
  ];
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  gridOptions : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true,
    tooltipShowDelay: 100,
    defaultColDef: {
      width: 165,
      minWidth: 20,
      filterParams: {
        filters: [
          {
            filter: "agSetColumnFilter"
          },
        ]
      } as IMultiFilterParams
    },
    sideBar: { toolPanels: ['columns', 'filters'] },
    getRowStyle: (params: any) => {
      if (this.url === "GetLineGraph" && params.data && params.data["gen"] && params.data["gen"] === 1) {
        return { backgroundColor: 'lightgray'};
      } else {
        return null;
      }
    }
    // onGridPreDestroyed: this.onGridPreDestroyed
  }
  gridApi: any;
  selectedType: string;
  userGridPreference: GridPreference = new GridPreference();
  navigatedExternal: boolean = false;
  url: string = "";
  report: boolean = false;
  renderer: Renderer2;
  constructor(private apiService : ApiService, private commonService : CommonService,public customMessageService: CustomMessageService, 
    private confirmationService : ConfirmationService, private router : ActivatedRoute, private config : DynamicDialogConfig,private readonly rendererFactory: RendererFactory2,
    private viewContainerRef: ViewContainerRef) {
    this.selectedType = 'Show all Assessments';
    this.renderer = rendererFactory.createRenderer(null,null);
    if(this.config.data) {
      this.idListText = this.config.data?.geIds ? this.config.data?.geIds : "";
      this.selectedCrop = this.config.data.report ? this.config.data.crop : this.crops.find(ele => ele.Name == this.config.data.crop);
      this.url = this.config.data.url;
      this.report = this.config.data.report;
      if(this.report)
        this.retrieveData();
    }
  }
  get idListText(): string {
    return this.idList.join("\r\n");
  }

  set idListText(value) {
    this.idList = value.split(/\r?\n/);
    if (this.idList.length < 2)
      this.idList = value.split(",");
    this.idList = this.idList.filter((v, i, a) => a.indexOf(v) === i);
    this.idList = this.idList.map(s => s.trim()).filter(s => s);
  }

  get crops(): Crop [] {
    return StaticFields?.crops && StaticFields?.crops?.length > 0 ? StaticFields?.crops?.filter(ele => ele.isActive) : [];
  }

  ngOnInit(): void {
    if (StaticFields.appUserId)
      this.setScreenData();  
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.setScreenData();
          clearInterval(interval);
        }
      }, 2000);
    }
  }

  setScreenData() {
    setTimeout(() => {
      const componentRef = this.viewContainerRef.createComponent(ReportModalHeaderComponent);
      componentRef.instance.incomingData = Object.create(this);
      let titleSpan = document.getElementsByClassName('p-dialog-header-icons')[0];
      this.renderer.insertBefore(titleSpan, componentRef.location.nativeElement,titleSpan.childNodes[0])
    },300);
  }
  
  // getUserGridPreferences(gridName: string, isSessionSelected: false, showLoader: boolean) {
  //   let params = new HttpParams();
  //   params = params.append('gridName', gridName);
  //   params = params.append('appUserId', StaticFields.appUserId);
  //   params = params.append('hasSessionData', isSessionSelected);
  //   this.apiService.getData('GetUserGridPreferrences', params, showLoader).subscribe((res: any) => {
  //     if (res.httpStatusCode == 200 && res.data) {
  //       StaticFields.lineGraphPageData = this.userGridPreference = res.data ? res.data : new GridPreference();
  //     }
  //   });
  // }

  setSearch($event: RadioButtonClickEvent) {
    if($event) {
      this.idList = []
     }
  };

  resetPreferences() {
    this.confirmationService.confirm({
      header: 'Reset',
      icon: 'pi pi-exclamation-triangle',
      message: `Do you want to reset the search criteria ?`,      
      accept: () => {
        this.selectedCrop = this.crops.find(ele => ele.Name === StaticFields.precodePreference.crop);
        this.idList = [];
        this.rowData.splice(0);
        this.collapseSearch = false;
        this.collapseResults = true;
        StaticFields.lineGraphPageData = null;
      }
    });
    
  }

  
  onGridPreDestroyed($event: GridPreDestroyedEvent<any,any>) {
    if (!$event.context.showGrouped &&  StaticFields.codingPageData)
      StaticFields.lineGraphPageData.colState = $event.api.getColumnState();
  }

  retrieveData() {
    let postData : any = {};
    postData['crop'] = this.selectedCrop.Name;
    if(this.searchBy == "CVN") {
      postData['geIds'] = [];
      postData['cvn'] = this.idList;
    } else {
      postData['geIds'] = this.idList.map(id => Number(id));
      postData['cvn'] = [];
    }
    this.apiService.postData(this.url, postData, false,true).subscribe({
      next: (response: any) => {
        if(response.httpStatusCode == 200) {
          if(response.data && response.data.length > 0) {
            setTimeout(() => {
              const ids : any = this.idList;
              if(this.searchBy == "CVN") {
                response.data.sort(function (a : any, b : any) {
                  return ids.indexOf(a.cvn) - ids.indexOf(b.cvn);
                });
              } else {
                let searchIds : any = [];
                ids.forEach((ele : any) => searchIds.push(Number(ele)));              
                response.data.sort(function (a : any, b : any) {
                  return searchIds.indexOf(Number(a.geid)) - searchIds.indexOf(Number(b.geid));
                });
              }  
              Object.keys(response.data[0]).forEach(k => {               
                this.columnDefs.push({ field: k, headerName: k.toUpperCase(), headerTooltip : k})
              });                                  
              this.rowData = [...response.data];
              this.collapseSearch = true;
              this.collapseResults = false;
            },500)
          } else {
            this.customMessageService.showMessage({severity: "warn",summary: "No Data Found", detail: "There was no data found for selected combination" })
          }
        }
      }
    });
  }

  export() {
    this.commonService.downloadExcelFile(this.agGrid,false,false);
  }

  saveGridPreferences() {
    const cols = JSON.stringify(this.gridApi.getColumnState());
    if (!this.userGridPreference?.appUserId) {
      this.userGridPreference.appUserId = StaticFields.appUserId;
      this.userGridPreference.gridName= this.selectedCrop.Name + ":" + "lineGraphPage";
    }
    StaticFields.lineGraphPageData.colState = this.userGridPreference.preference = cols;
    this.apiService.putData('SubmitUserGridPreference', this.userGridPreference).subscribe();   
  }



  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
    let preference = null;
    let savedCols = null;
    
     if (this.userGridPreference?.preference) {
       savedCols = JSON.parse(this.userGridPreference.preference);
    }

    this.gridApi.applyColumnState({ state: savedCols, applyOrder: true });
  }
}
