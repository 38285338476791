import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ColDef, GridReadyEvent, SelectionChangedEvent, IMultiFilterParams, FilterChangedEvent, GridPreDestroyedEvent,RowGroupingDisplayType, IRichCellEditorParams, GridOptions, CellPosition } from 'ag-grid-community';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { StaticFields } from 'src/app/shared/staticFields';
import { ColorFilter } from 'src/app/shared/colorFilter';
import { SetCustomColorComponent } from 'src/app/shared/setCustomColor';
import { LoadingService } from 'src/app/services/loading.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GeassessmentmodalComponent } from '../geassessmentmodal/geassessmentmodal.component';
import { GridPreference } from 'src/app/models/gridPreference';
import { Crop } from 'src/app/models/crop';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Router } from '@angular/router';
import { UserPreferredGeTrait } from 'src/app/models/userPreferredGeTrait';
import { ContextMenu } from 'primeng/contextmenu';
import { CommonreportmodalComponent } from '../commonreportmodal/commonreportmodal.component';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonService } from 'src/app/services/common.service';
import { CropUpdateService } from 'src/app/services/cropUpdate.service';

@Component({
  selector: 'app-manageprogram',
  templateUrl: './manageprogram.component.html',
  styleUrl: './manageprogram.component.scss'
})
export class ManageprogramComponent {
  @ViewChild('cm') cm: ContextMenu;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  cmItems: MenuItem[] = [{label: 'Share', icon: 'pi pi-user-plus', command: () => this.shareSession((this.cm.target as any).textContent)}, {label: 'Delete', icon: 'pi pi-trash', command: () => this.deleteSession((this.cm.target as any).textContent)}];
  gridApi: any;
  rowData: any[] = [];
  rowDataCopy: any[] = [];
  expandCheckBox: boolean = true;
  genToExpand: any;
  genToExpandAll : any;
  gridColDef: ColDef[] = [];
  gridOptions : GridOptions = {
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
    tooltipShowDelay: 100,
    columnDefs: this.gridColDef,
    defaultColDef: {
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: ColorFilter
          },
          {
            filter: "agSetColumnFilter"
          },
        ],
        refreshValuesOnOpen: true
      } as IMultiFilterParams,
      resizable: true,
      minWidth: 50,
      cellStyle: (params: any) => {
        const cellId = `${params.column.getColId()}_cellColor`;
        if (StaticFields.precodeScreenData.selNodes && StaticFields.precodeScreenData.selNodes.indexOf(params.data.id) > -1) {
          return null;
        } else if (params.data && params.data[cellId] && params.data[cellId] !== 'No Fill') {
          if (!this.existingGridColors.includes(params.data[cellId]))
            this.existingGridColors.push(params.data[cellId]);
          return { backgroundColor: params.data[cellId]};
        } else {
          return null;
        }
      }
    },
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
        { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
        {
          statusPanel : SetCustomColorComponent,
          align: 'left',
        },
        { statusPanel: 'agAggregationComponent' }
      ],
    },
    getContextMenuItems: this.getContextMenuItems,
    sideBar: { toolPanels: ['columns', 'filters'] },
    onGridPreDestroyed: this.onGridPreDestroyed,
    context: {
      componentParent: this
    },
   
  };
  public groupDisplayType: RowGroupingDisplayType = "multipleColumns";
  selColor: any = '#ffffff';
  selectedCrop: any;
  metaDataList: any [] = [];
  selectedMetaData: any;
  levels: any[] = [{name: 'Program(s)'},{name: 'Region(s)'},{name: 'EZ(s)'}];
  selectedLevel: any;
  inclusions: any[];
  selectedInclusions: any;
  allStageValues: any = [];
  stages: string[] = [];
  selectedStages: any;
  years: string[] = StaticFields.years;
  selectedYears: any[] = [];
  geLineageChecked: boolean = false;
  seedInventoryChecked: boolean = false;
  popRequestChecked: boolean = false;
  experimentsChecked: boolean = false;
  apexVoteChecked : boolean = false;
  regions: any[] = [];
  ezs: any = [];
  programs: any = [];
  collapseResultPanel: boolean = true;
  collapseSearchPanel: boolean = false;
  userGridPreference : GridPreference = new GridPreference();
  //precodePreference : PreCodePreferences = new PreCodePreferences();
  settingPageLoadData: boolean = false;
  foundCell: any[] = [];
  foundIndex = 0;
  lastFoundCell: any;
  found = false;
  showFindReplace = false;
  findText: string;
  replaceText: string;
  matchCaseChecked = false;
  matchWordChecked = false;
  existingGridColors: any[] = [];
  showColorSelector: boolean = false;
  showSaveModal: boolean = false;
  expandByAllOptions = ['All'];
  sessionName: string = '';
  sharedById: any;
  sessions: any = [];
  replaceSession: boolean = false;
  selSession: any;
  ref: DynamicDialogRef;
  genToFetch: any = {name:"0", val: 0};
  genList: any = [{name:"All", val: -100},{name:"5", val: 5},{name:"4", val: 4},{name:"3", val: 3},{name:"2", val: 2},{name:"1", val: 1},{name:"0", val: 0},{name:"-1", val: -1},{name:"-2", val: -2},{name:"-3", val: -3},{name:"-4", val: -4},{name:"-5", val: -5}];
  showPStageModal: boolean = false;
  pStageData: any[] = [];
  favProgramsChecked: boolean = false;
  pStageColDef: ColDef[] = [
    { field: 'crop', headerName: 'Crop',cellEditor: 'agSelectCellEditor', editable: true, onCellValueChanged(event) {
      if (event.data.crop !== event.context.componentParent.selectedCrop.Name) {
        event.context.componentParent.changeCrop(true,event.data.crop);
      }
    },
      cellEditorParams: () => { return { values: this.crops.map((ele:any) => ele.Name), valueListMaxHeight: 250 } } },
    { field: 'type', headerName: 'Type', cellEditor: 'agSelectCellEditor', editable: true,
      cellEditorParams: () => { return { values: ["Program(s)","Region(s)","EZ(s)"], valueListMaxHeight: 100 } }},
    { field: 'name', headerName: 'Name', cellEditor: 'agRichSelectCellEditor' , editable: true,
      cellEditorSelector: params => {
        let type : any[] = [];
        if(params.data.crop === this.selectedCrop.Name)
           type = params.data.type === 'Program(s)' ? this.programs : params.data.type === 'Region(s)' ? this.regions : this.ezs;   
        else
           type = params.data.type === 'Program(s)' ? this.pStagePrograms : params.data.type === 'Region(s)' ? this.pStageRegions : this.pStageEzs;        
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: type.map((d: any) => d.Code),
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            multiSelect: true
          }
        }
      }
    },
    {field: 'year',headerName : 'Year(s)',  cellEditor: 'agRichSelectCellEditor', editable: true,
      cellEditorParams: {
        values: StaticFields.years,
        multiSelect: true,
        searchType: 'matchAny',
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams    
       
    }
  ];
  defaultPStageColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth : 20, 
    resizable: true,
  } ;
  gridOptionsPStage : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true
  }
  pStageDataCopy: any;
  pStageGridApi: any;
  selectedPStageCrop: any;
  pStageRegions: any;
  pStageEzs: any;
  pStagePrograms: any;
  geAssessmentsList: UserPreferredGeTrait[] = [];
  selectedAssessments: any[] = [];
  selectedPStages: any[] = [];
  pStageCounter: number = 0;
  pStagesList: any[] = [];
  showADDialog: boolean = false;
  sessionToShare: string;
  searchMemberString: string;
  shareDescription: string;
  adUserData: any = [];
  userGridApi: any;
  userDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 20
  };
  userColumnDefs: ColDef[] = [
    { field: 'displayName',headerName: 'Display Name', checkboxSelection: true, filter: 'agTextColumnFilter', width: 200 },
    { field: 'onPremisesSamAccountName',headerName: 'User Name', filter: 'agTextColumnFilter', width: 150 },
    { field: 'mail', headerName: 'Email',filter: 'agTextColumnFilter', flex: 2}
  ];
  showBulkEditDialog: boolean = false;
  editCommentsChecked: boolean = false;
  editMetadataChecked: boolean = false;
  editCusMetadataChecked: boolean = false;
  bulkComments: string;
  bulkMetaData: any = [];
  bulkCusMetaData: string;
  bulkEditStages: string[] = [];
  selBulkEditStage: string;
  showPStageGrid: boolean = true;
  showGeTraitGrid: boolean = true;
  cropReset: boolean;
  showAPEXJobs: boolean = false;
  apexGridApi: any;
  apexData: any[] = [];
  apexColDef: ColDef[] = [
    // { field: 'crop', headerName: 'Crop' },
    { field: 'name', headerName: 'Name', width: 350 },
    { field: 'jobType', headerName: 'Owner', width: 240 },
    { field: 'createDate', headerName : 'Created Date', width: 140, valueFormatter: p => {
        const d = p.value == null ? new Date() : new Date(p.value);
        return p.value == null ? '' : `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
      }
    },
    { field: 'description', headerName : 'Description', width: 350 },
    { field: 'isVoting', headerName : 'Is Voting', cellDataType: 'text', valueFormatter: p => p.value ? 'Yes' : 'No', width: 120 },
  ];
  apexStatusBar = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' }
    ]
  }

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService,private commonService: CommonService, private router : Router,
    private confirmationService: ConfirmationService , public loader: LoadingService,public dialogService: DialogService,  private cropUpdateService : CropUpdateService) { 
      this.cropUpdateService.resetData$.subscribe(res => {
        if (res) {
          this.cropReset = true;
          this.resetSearch();
        }
      });
     }

  get allowReset() : boolean {
    if (StaticFields.precodePreference && this.selectedCrop && this.selectedLevel) {
      return this.selectedCrop.Name !== StaticFields.precodePreference.crop || this.selectedLevel.name !== StaticFields.precodePreference.level || 
      (this.selectedInclusions && this.selectedInclusions.length > 0 && JSON.stringify(this.selectedInclusions.map((i: any) => i.code).sort((a: any,b : any) => a-b)) !== JSON.stringify(StaticFields.precodePreference.include.split(",").sort((a: any,b: any) => a-b))) ||
      (this.selectedStages && this.selectedStages.length > 0 && JSON.stringify(this.selectedStages.sort((a: any,b : any) => a-b)) !== JSON.stringify(StaticFields.precodePreference.stage.split(",").sort((a: any,b: any) => a-b))) ||
      (this.selectedYears && this.selectedYears.length > 0 && JSON.stringify(this.selectedYears.sort((a: any,b : any) => a-b)) !== JSON.stringify(StaticFields.precodePreference.years.split(",").sort((a: any,b: any) => a-b))) ||
      this.geLineageChecked !== StaticFields.precodePreference.geLineage || this.popRequestChecked !== StaticFields.precodePreference.popRequest || this.apexVoteChecked !== StaticFields.precodePreference.apexVote ||
      this.seedInventoryChecked !== StaticFields.precodePreference.seedInventory || this.experimentsChecked !== StaticFields.precodePreference.experiments || this.rowData.length > 0;  
    } else {
      return this.selectedCrop || this.geLineageChecked || this.seedInventoryChecked || this.popRequestChecked || this.experimentsChecked || this.apexVoteChecked ||this.rowData.length > 0;
    }
  }

  get updatedData(): any {
    return this.rowData.filter(g => g.hasChanges);
  }

  get crops(): Crop [] {
    return StaticFields.crops?.filter(ele => ele.isActive);
  }

  get hasFilterApplied(): boolean {
    if (this.gridApi && !this.gridApi.isDestroyed() && this.gridApi.getFilterModel())
      return Object.keys(this.gridApi.getFilterModel()).length > 0;
    return false;
  }

  get validPstageData() : number {
    let count = 0;
    this.pStageData.forEach(ele => {
      if(ele.type !== "" && (ele.name.length > 0 && ele.year.length > 0)) {
        count++;
      }
    })
    return count;
  }

  get allowPStageSettingSave() : boolean {
    return JSON.stringify(this.pStageData) === JSON.stringify(this.pStageDataCopy);
  }

  get getUserSelectedRows() : any {
    if (this.userGridApi)
      return this.userGridApi.getSelectedRows();
    return null;
  }

  ngOnInit(): void {
    if (StaticFields.appUserId)
      this.initialCalls();
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.initialCalls();
          clearInterval(interval);
        }
      }, 600);
    }

    window.addEventListener("keydown", function (e) {
      if (e.key === 'f' && e.ctrlKey && (e.target as any).role === 'gridcell') { 
        e.preventDefault();
      }
    });
  }

  initialCalls() {
    this.getSavedSessionsForUser();
    if (StaticFields.precodeScreenData && StaticFields.precodeScreenData.crop) {
      this.settingPageLoadData = true;   
      this.setScreenData();
    } else if (StaticFields.precodePreference)
      this.setScreenData();
    this.getUserGridPreferences('managePrecode', false, false);
  }

  getSavedSessionsForUser() {
    let params = new HttpParams();
    params = params.append('appUserId', StaticFields.appUserId);
    this.apiService.getData('GetSavedSessionsForUser', params, false).subscribe((res: any) => {
      if (res.httpStatusCode == 200 && res.data) {
        this.sessions = res.data;
      }
    });
  }

  getUserGridPreferences(gridName: string, isSessionSelected: boolean, showLoader: boolean) {
    let params = new HttpParams();
    params = params.append('gridName', gridName);
    params = params.append('appUserId', StaticFields.appUserId);
    params = params.append('hasSessionData', isSessionSelected);
    this.apiService.getData('GetUserGridPreferrences', params, showLoader).subscribe((res: any) => {
      if (res.httpStatusCode == 200 && res.data) {
        let data = res.data[0];
        if (isSessionSelected) {
          let existing_Data = data.existingData[0];
          this.sharedById = existing_Data.sharedByUserId;
          const existingData = JSON.parse(existing_Data.preference);
          if (data.refreshedData) {
            existingData.data.forEach((d: any) => {
              const currentData = data.refreshedData[d.id];
              if (currentData) {
                Object.keys(currentData).forEach(col => {
                  if (col !== 'id' && col !== 'geId' && col !== 'crop' && currentData[col])
                    d[col] = currentData[col];
                });
                // d.comments = currentData.comments;
                // d.metaData = currentData.metaData;
                // d.customMetaData = currentData.customMetaData;
                // d.geName = currentData.geName;
                // d.cvn = currentData.cvn;
                // d.pedigree = currentData.pedigree;
                // d.parentage = currentData.parentage;
                // d.heteroticGroup = currentData.heteroticGroup;
                // d.year = currentData.year;
                // d.stage = currentData.stage;
              }
            });
          }
          StaticFields.precodeScreenData = existingData;
          StaticFields.precodeScreenData.selSession = this.selSession;
          this.settingPageLoadData = true;
          this.setScreenData();
        } else
          this.userGridPreference = data ? data : new GridPreference();
      }
    });
  }
  
  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
    this.gridApi.setGridOption('columnDefs', this.gridColDef);
    if (this.settingPageLoadData) {
      if (StaticFields.precodeScreenData.selNodes) {
        if(this.gridApi) {
          this.gridApi.forEachNode((node: any) => { 
            const selNodes = StaticFields.precodeScreenData.selNodes;
            if (selNodes.find((id: any) => id === node.data.id || id === node.id))
              node.setSelected(true);
          });
        }
      }
      if (StaticFields.precodeScreenData.colState)
        this.gridApi.applyColumnState({ state: StaticFields.precodeScreenData.colState, applyOrder: true });
      if (StaticFields.precodeScreenData.filters)
        this.gridApi.setFilterModel(StaticFields.precodeScreenData.filters);
      this.settingPageLoadData = false;
    } else if (this.userGridPreference.preference) {
      const savedCols = JSON.parse(this.userGridPreference.preference);
      this.gridApi.applyColumnState({ state: savedCols, applyOrder: true });
    }
  }

  changeCrop(isPStage? : boolean, crop? : string) {
    if(!isPStage)
      this.selectedInclusions = this.selectedStages = null;
    this.getMasterData(isPStage,crop);  
    if(!isPStage)  
      this.getMetaData();
  }

  changeLevel() {
    this.selectedInclusions = null;
    if (this.selectedLevel.name === "Program(s)") {
      this.inclusions = this.programs.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.selectedLevel.name === "Region(s)") {
      this.inclusions = this.regions.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.selectedLevel.name === "EZ(s)") {
      this.inclusions = this.ezs.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.inclusions)
      this.inclusions.splice(0);
    if (this.settingPageLoadData && StaticFields.precodeScreenData && StaticFields.precodeScreenData.include) {
      this.selectedInclusions = this.inclusions.filter(i => StaticFields.precodeScreenData.include.includes(i.code));
      this.selectedStages = StaticFields.precodeScreenData.stage;
      this.selectedYears = StaticFields.precodeScreenData.years;
      this.rowData = StaticFields.precodeScreenData.data;
      this.selColor = StaticFields.precodeScreenData.selectedColor;
      this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
      if (StaticFields.precodeScreenData.selSession) {
        this.selSession = StaticFields.precodeScreenData.selSession;
        this.sessionName = this.selSession.name;
      }
      this.createDynamicCols();
      if (this.rowData.length > 0) {
        this.collapseResultPanel = false;
        this.collapseSearchPanel = true; 
      }
    } else if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId && this.inclusions.length > 0) {
      const inclPredicate = StaticFields.precodePreference.include.split(",").some(ele => this.inclusions.findIndex((i: any) => i.code === ele) > -1);
      const stagePredicate = StaticFields.precodePreference.stage.split(",").some(ele => this.stages.includes(ele));
      const selInclusions = inclPredicate && StaticFields.precodePreference.include!= "" && StaticFields.precodePreference.include.split(",").length > 0 ? StaticFields.precodePreference.include.split(",") : [];
      this.selectedStages = stagePredicate &&  StaticFields.precodePreference.stage!= "" && StaticFields.precodePreference.stage.split(",").length > 0 ? StaticFields.precodePreference.stage.split(",") : [];
      this.selectedInclusions = this.inclusions.filter(i => selInclusions.includes(i.code));
    }
  }

  getRegionsPrograms(isPStage ?:  boolean, crop? : string) {
    let params = new HttpParams();
    const cropId = isPStage ? this.crops.find(ele => ele.Name === crop).Id : this.selectedCrop.Id;
    params = params.append('cropId', cropId);
    this.apiService.getRADData(`Regions?$filter=CropId eq ${cropId} and Status/Name eq 'Active'&$select=Id,Code,Description`, false).subscribe((result : any) => {
      result.value.push({Code: 'WW', Description: 'Worldwide'});
      result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code));  
      if(!isPStage) {
        this.regions = result.value;
        this.loader.clearMessage();
        if (this.selectedLevel && this.selectedLevel.name === "Region(s)")
          this.changeLevel();
        } else {
          this.pStageRegions = result.value;
          this.loader.clearMessage();
        }     
    });
    this.apiService.getRADData(`EvaluationZones?$filter=CropId eq ${cropId} and Status/Name eq 'Active'&$select=Id,Code,Description`, false).subscribe((result : any) => {
        result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code));
        if(!isPStage) {
          this.ezs = result.value;
          this.loader.clearMessage();
          if (this.selectedLevel && this.selectedLevel.name === "EZ(s)")
            this.changeLevel();
        }
        else {
          this.pStageEzs = result.value;
          this.loader.clearMessage();
        }     
    });
    this.apiService.getRADData(`Programs?$filter=CropId eq ${cropId} and Status/Name eq 'Active'&$select=Id,Code,Description`, false).subscribe((result : any) => {
        result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code)); 
        if(!isPStage) {
          this.programs = result.value;
          this.loader.clearMessage();
          if (this.selectedLevel && this.selectedLevel.name === "Program(s)")
            this.changeLevel();
        }
        else {
          this.pStagePrograms = result.value;
          this.loader.clearMessage();
        }    
    });
  }

  getMasterData(isPStage? : boolean, crop? : string) {
    if(!isPStage) {
      this.allStageValues = StaticFields.masterData?.filter(p => p.type == 'Stage' && p.crop == this.selectedCrop.Name && p.isActive); 
      this.stages = this.allStageValues.map((s: any) => s.stage);
    }
    this.getRegionsPrograms(isPStage,crop);      
  }

  
  getMetaData() {   
    this.metaDataList = StaticFields.metaData?.filter(p => (p.crop === "ALL" || p.crop === this.selectedCrop.Name) && p.isActive).map((d: any) => d.name).sort((a: any, b: any) => (a > b) ? 1 : ((b > a) ? -1 : 0));
  }

  SearchData() {
    this.rowData.splice(0);
    this.rowDataCopy.splice(0);
    const expandBy = this.getExpandOptions();
    let postData : any = {};
    postData['crop'] = this.selectedCrop.Name;
    postData['level'] = this.selectedLevel.name;
    postData['include'] = this.selectedInclusions.map((i: any) => i.code).join();
    postData['stage'] = this.selectedStages ? this.selectedStages.join() : '';
    postData['years'] = this.selectedYears ? this.selectedYears.join() : '';
    postData['expandBy'] = expandBy;
    postData['genToFetch'] = this.genToFetch.val;
    postData['stageInfoToFetch'] = [];
    this.selectedPStages.forEach((ele : any) => {
      const type = ele.type === 'EZ(s)' ? 'EZ' : ele.type == 'Region(s)' ? 'Region' :'PRG';
      const names = ele.name.split(",");  
      const years = ele.year.split(","); 
      names.forEach((name : any) => {
        years.forEach((year : any) => {
          postData['stageInfoToFetch'].push({
            type: type,
            name : name,
            year: year,
            pStage:'',
            crop: ele.crop
          });
        });
      });
    });
    postData['geAssessments'] = [];
    this.selectedAssessments.forEach((ele: any) => {
      postData['geAssessments'].push({key: ele.traitId, value: ele.traitName});
    });
    this.existingGridColors.splice(0);
    this.selSession = null;
    this.sessionName = '';
    this.apiService.postData('SearchPreCodes', postData, false, true).subscribe((result : any) => {
      if (result.httpStatusCode == 200) {
        if (result.data && result.data.length > 0) { 
          this.collapseResultPanel = false;
          this.collapseSearchPanel = true;
          this.rowDataCopy = JSON.parse(JSON.stringify(result.data));
          const precodeData: any = new Object();
          precodeData.data = this.rowData = result.data;
          precodeData.crop = this.selectedCrop;
          precodeData.level = this.selectedLevel;
          precodeData.include = this.selectedInclusions.map((i: any) => i.code);
          precodeData.stage = this.selectedStages;
          precodeData.years = this.selectedYears;
          precodeData.expandBy = expandBy;
          precodeData.gensToSummarize = this.genToFetch;
          precodeData.geAssessmentsList = this.geAssessmentsList;
          precodeData.pStageData = this.pStageData;
          precodeData.pStagesList = this.pStagesList;
          precodeData.selectedAssessmentsList = this.selectedAssessments;
          precodeData.selectedPStages = this.selectedPStages
          precodeData.isFavouriteSelected = this.favProgramsChecked;
          StaticFields.precodeScreenData = precodeData;
          
          this.createDynamicCols();
        } else
          this.customMessageService.showMessage({'severity': 'warn', summary: 'No Data', detail: result.message });
      } 
    });
  }

  private createDynamicCols() {
    this.gridColDef.splice(0);
    const metaDataCols: string[] = [];
    Object.keys(this.rowData[0]).forEach(k => {
      if (k !== 'id' && !k.includes('_cellColor')) {
        const allowEdit = (k.includes('pstage') || k.includes('MetaData') || k.includes('Comments')) && !(k.includes("Region") || k.includes("EZ"));
        if (allowEdit) {
          if (k.includes('pstage')) {
            this.gridColDef.push({ field: k, headerName: k.toUpperCase(), headerTooltip : k,editable: true, 
              cellEditor: 'agSelectCellEditor', cellEditorParams: (params: any) => { return { values: this.stages }; } });
            const stageName = k.split('_')[1];
            if (!this.bulkEditStages.includes(stageName))
              this.bulkEditStages.push(stageName);
          } else if (k.includes('_MetaData')) {
            metaDataCols.push(k);
            this.gridColDef.push({ field: k, headerName: k.toUpperCase(),  headerTooltip : k, editable: true, cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: {
                values: this.metaDataList,
                multiSelect: true,
                searchType: 'matchAny',
                filterList: true,
                highlightMatch: true 
              } 
            });
          } else if (k.includes('_CustomMetaData'))
            this.gridColDef.push({ field: k, headerName: k.toUpperCase(),  headerTooltip : k, editable: true, cellEditorParams: {maxLength: 25} });
            else 
            this.gridColDef.push({ field: k, headerName: k.toUpperCase(),   headerTooltip : k, editable: true });
        } else {
          let width = (k === "crop" || k === "geId" || k === "hetgrp") ? 110 : 150;
          this.gridColDef.push({ field: k, headerName: k.toUpperCase(), headerTooltip : k, width: width });
        }
      }
    });
    this.rowData.forEach(ele => {
      metaDataCols.forEach(col => {
        ele[col] = ele[col] ? ele[col].split(",") : [];
      });
    });
  }

  getExpandOptions() {
    const expandBy: string[] = [];
    if (this.geLineageChecked)
      expandBy.push('GE');
    if (this.seedInventoryChecked)
      expandBy.push('SI');
    if (this.popRequestChecked)
      expandBy.push('POP');
    if (this.experimentsChecked)
      expandBy.push('EXPT');
    if (this.apexVoteChecked)
      expandBy.push('APEX');
    return expandBy.join();
  }

  setExpandOptions(expandBy: string[]) {
    this.geLineageChecked = this.experimentsChecked = this.seedInventoryChecked = this.popRequestChecked = this.apexVoteChecked = false;
    expandBy.forEach(item => {
      if (item === 'GE')
        this.geLineageChecked = true;
      else if (item === 'SI')
        this.seedInventoryChecked = true;
      else if (item === 'POP')
        this.popRequestChecked = true;
      else if (item === 'EXPT')
        this.experimentsChecked = true;
      else if (item === 'APEX')
        this.apexVoteChecked = true;
    });
  }

  saveGridPreferences() {
    const cols = JSON.stringify(this.gridApi.getColumnState());
    if (!this.userGridPreference.appUserId) {
      this.userGridPreference.appUserId = StaticFields.appUserId;
      this.userGridPreference.gridName= "managePrecode";
    }
    this.userGridPreference.preference = cols;
    this.apiService.putData('SubmitUserGridPreference', this.userGridPreference).subscribe();   
  }

  savePageState() {
    this.showSaveModal = true;    
  }

  saveSession() {
    const selSession = this.sessions.find((s: any) => s.name === this.sessionName);
    if (selSession && (selSession.count > 0 || selSession.sharedBy)) {
      const msg = selSession.count > 0 ? `Session: ${this.sessionName} is shared with ${selSession.count} user(s). Do you want to update for everyone?` : `Session: ${this.sessionName} was shared with you. Do you want to update for everyone?`;
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: msg,   
        acceptLabel: 'Update for All',
        rejectLabel: 'Update for Me',
        acceptIcon: "none",
        rejectIcon: "none",
        accept: () => {
          this.saveSessionAfterConfirming(true);
        },
        reject: () => {
          this.saveSessionAfterConfirming(false);
        }
      });
    } else
      this.saveSessionAfterConfirming(false);
    
  }

  saveSessionAfterConfirming(updateForAll: boolean) {
    StaticFields.precodeScreenData.expandBy = this.getExpandOptions();
    StaticFields.precodeScreenData.colState = this.gridApi.getColumnState();
    const data = StaticFields.precodeScreenData.data;
    let pageState: GridPreference = new GridPreference();
    pageState.appUserId = StaticFields.appUserId;
    pageState.gridName= this.sessionName;
    pageState.preference = JSON.stringify(StaticFields.precodeScreenData);
    pageState.isSession = true;
    pageState.sharedByUserId = this.sharedById;
    const submitData = { preference: pageState, updateAll: updateForAll };
    this.apiService.putData('SaveSession', submitData).subscribe((res: any) => { 
      if (res.httpStatusCode == 200) {
        if (this.sessions.findIndex((s: any) => s.name === this.sessionName) === -1) {
          this.sessions.push({name: this.sessionName});
          this.sessions.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.selSession = this.sessions.find((s: any) => s.name === this.sessionName);
          const data = this.sessions.splice(0);
          this.sessions = [...data];
        }
      }
      this.showSaveModal = false;
    } );
  }

  deleteSession(sessionToDel: string) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to delete Session: ${sessionToDel}?`,     
      accept: () => {
        const sharedCount = this.sessions.find((s: any) => s.name === sessionToDel).count;
        if (sharedCount > 0) {
          setTimeout(() => {
            this.confirmationService.confirm({
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              message: `Session: ${sessionToDel} is shared with ${sharedCount} user(s). Do you want to delete for everyone?`,   
              acceptLabel: 'Delete for All',
              rejectLabel: 'Delete for Me',
              acceptIcon: "none",
              rejectIcon: "none",
              accept: () => {
                this.deleteSessionAfterConfirming(sessionToDel, true);
              },
              reject: () => {
                this.deleteSessionAfterConfirming(sessionToDel, false);
              }
            });
          }, 600);
        } else
          this.deleteSessionAfterConfirming(sessionToDel, false);
      }
    });
  }

  deleteSessionAfterConfirming(sessionToDel: string, deleteForAll: boolean) {
    let params = new HttpParams();
    params = params.append('name', sessionToDel);
    params = params.append('appUserId', StaticFields.appUserId);
    params = params.append('deleteForAll', deleteForAll);
    this.apiService.getData('DeleteSessionByName', params).subscribe((result : any) => {
      if (result.httpStatusCode == 200) {
        this.selSession = null;
        this.sessions.splice(this.sessions.findIndex((s: any) => s.name === sessionToDel), 1);
        const data = this.sessions.splice(0);
        this.sessions = [...data];
        this.customMessageService.showMessage({'severity': 'success', summary: 'Session Removed', detail: result.message });
        if (this.sessionName === sessionToDel) {
          this.rowData.splice(0);
          this.rowDataCopy.splice(0);
        }
      }
    });
  }

  resetSearch() {
    if(!this.cropReset) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `Are you sure you want to reset the screen?`,
        accept: () => {
          // if (StaticFields.precodeScreenData && StaticFields.precodeScreenData.crop) {
          //   this.setScreenData(false);
          // } else {
          StaticFields.precodeScreenData = null;
          if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId) {
            this.setScreenData();
          } else {
            this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = this.selectedYears = null;
            this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = this.apexVoteChecked = false;
          }
          this.selColor = '#ffffff';
          this.selSession = null;
          this.sessionName = '';
          this.rowData.splice(0);
          this.rowDataCopy.splice(0);
          this.collapseResultPanel = true;
          this.collapseSearchPanel = false;
          this.favProgramsChecked = false;
          this.genToFetch = {name: "0", val : 0}
          //}
        }
      });
    } else {
      this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = this.selectedYears = null;
      this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = this.apexVoteChecked = false;
      this.selColor = '#ffffff';
      this.selSession = null;
      this.sessionName = '';
      this.rowData.splice(0);
      this.rowDataCopy.splice(0);
      this.collapseResultPanel = true;
      this.collapseSearchPanel = false;
      this.favProgramsChecked = false;
      this.genToFetch = {name: "0", val : 0};
      this.cropReset = false;
    }
    
  }

  navigateToCode() {
    const geIds = this.gridApi.getSelectedRows().map((ele: any) => ele.geId);
    setTimeout(() => {
      this.router.navigate(['/code'], {queryParams : {selectedGes: geIds,crop: this.selectedCrop.Name}});
    },300);
  }

  navigateToLineGraph() {
    this.displayReport('linegraph');
    // const geIds = this.gridApi.getSelectedRows().map((ele: any) => ele.geId);
    // setTimeout(() => {
    //   this.router.navigate(['/linegraph'], {queryParams : {selectedGes: geIds,crop: this.selectedCrop.Name}});
    // },300);
  }

  updateColor(event: any) {
    if (event)
      this.selColor = event.target.value;
    StaticFields.precodeScreenData['selectedColor'] = this.selColor;
    this.showColorSelector = false;
  }

  setScreenData() {
    if (this.settingPageLoadData) {
      if (this.crops.find(ele => ele.Name == StaticFields.precodeScreenData.crop.Name)) {
        this.selectedCrop = StaticFields.precodeScreenData.crop;
        this.selectedLevel = StaticFields.precodeScreenData.level;
        this.geAssessmentsList = StaticFields.precodeScreenData.geAssessmentsList;
        this.pStageData = StaticFields.precodeScreenData.pStageData;
        this.pStagesList = StaticFields.precodeScreenData.pStagesList;
        this.selectedAssessments = StaticFields.precodeScreenData.selectedAssessmentsList;
        this.selectedPStages = StaticFields.precodeScreenData.selectedPStages;
        this.favProgramsChecked = StaticFields.precodeScreenData.isFavouriteSelected;
        this.getMasterData();    
        this.getMetaData(); 
        const expandBy = StaticFields.precodeScreenData.expandBy.split(',');
        this.setExpandOptions(expandBy);
      } else {
        this.customMessageService.showMessage({severity: 'warn', summary:`Crop ${StaticFields.precodeScreenData.crop.Name} is inactive` , detail: `Session crop ${StaticFields.precodeScreenData.crop.Name} is inactive, can't load session.`});
      }
    } else if(StaticFields.precodePreference && StaticFields.precodePreference.appUserId) {
      this.selectedCrop = this.crops.find(ele => ele.Name == StaticFields.precodePreference.crop);
      if (this.selectedCrop) {
        this.selectedLevel = this.levels.find(ele => ele.name == StaticFields.precodePreference.level);
        this.selectedYears = StaticFields.precodePreference.years !== "" && StaticFields.precodePreference.years.split(",").length > 0 ? StaticFields.precodePreference.years.split(",") : [];
        this.getMasterData();   
        this.getMetaData(); 
        this.geLineageChecked = StaticFields.precodePreference.geLineage;
        this.popRequestChecked = StaticFields.precodePreference.popRequest;
        this.seedInventoryChecked = StaticFields.precodePreference.seedInventory;
        this.experimentsChecked = StaticFields.precodePreference.experiments;
        this.apexVoteChecked = StaticFields.precodePreference.apexVote;
        this.genToFetch = StaticFields.precodePreference.gensToSummarize;
        this.geAssessmentsList = JSON.parse(JSON.stringify(StaticFields.precodePreference.userPreferredGeTraits));
        this.selectedAssessments = this.geAssessmentsList.filter(ele => ele.selected == true);
        this.pStagesList =  JSON.parse(JSON.stringify(StaticFields.precodePreference?.userPreferredPStages ? StaticFields.precodePreference.userPreferredPStages : [] ));   
        this.pStageData =  JSON.parse(JSON.stringify(StaticFields.precodePreference?.userPreferredPStages ? StaticFields.precodePreference.userPreferredPStages : [] ));   
        this.pStageData.forEach(ele => {ele.name = ele.name.split(",");ele.year = ele.year.split(",")});
        this.selectedPStages = this.pStagesList.filter(ele => ele.selected == true);
      }
      else {
        this.customMessageService.showMessage({severity: 'warn', summary:`Crop ${StaticFields.precodePreference.crop} is inactive` , detail: `Saved crop ${StaticFields.precodePreference.crop} is inactive, can't load preferences.`});
      }
    }
  }

  cancelGridChanges() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to cancel the changes made ?`,     
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(this.rowDataCopy));
      }
    });
  }

  clearFilters() {
    this.gridApi.setFilterModel(null);
  }

  saveChanges() {
    const dataToSubmit: any = [];
    this.updatedData.forEach((d: any) => {
      const existingRow = this.rowDataCopy.find(g => g.id === d.id);
      Object.keys(existingRow).forEach(k => {
        if (k.includes('_') && existingRow[k] !== d[k]) {
          const parts = k.split('_');
          const program: any = {};
          program.geId = d.geId;
          program.crop = d.crop;
          program.year = 0;
          program.stage = '';
          if (parts.length === 3){
            program.name = parts[1];
            program.year = parts[2];
            program.stage = d[k];
          } else if (parts[1] === 'Comments') {
            program.name = parts[0].toUpperCase();
            program.comments = d[k];
          } else if (parts[1] === 'MetaData') {
            program.name = parts[0].toUpperCase();
            program.metaData = d[k].join();
          } else {
            program.name = parts[0].toUpperCase();
            program.customMetaData = d[k];
          }
          dataToSubmit.push(program);
        }
      });
    });
    this.apiService.putData("SubmitPreCodeData", dataToSubmit).subscribe();
  }

  onCellValueChanged(item: any) {
    if (item.data.hasChanges) {
      const existingVal = this.rowDataCopy.find(d => d.id === item.data.id);
      item.data.hasChanges = existingVal[item.column.colId] !== item.newValue;
    } else
      item.data.hasChanges = true;
  }

  onSelectionChanged($event: SelectionChangedEvent<any,any>) {
    const lastCellCliked: CellPosition = this.gridApi.getFocusedCell();
    const rowNodes : any[] = [];
    if (StaticFields.precodeScreenData.selNodes && StaticFields.precodeScreenData.selNodes.length > 0) {
      this.gridApi.forEachNode((node: any) => { 
        const selNodes = StaticFields.precodeScreenData.selNodes;
        if (selNodes.find((id: any) => id === node.data.id || id === node.id))
          rowNodes.push(node);
      });
      StaticFields.precodeScreenData.selNodes = [];
      this.gridApi.redrawRows({ rowNodes: rowNodes });
    } else 
      StaticFields.precodeScreenData.selNodes = [];
    const selNodes = this.gridApi.getSelectedNodes();
    if (selNodes.length > 0) {
      rowNodes.splice(0);
      selNodes.forEach((n: any) => {
        if (n?.data)
          StaticFields.precodeScreenData.selNodes.push(n.data.id);
        rowNodes.push(n);
      });
      this.gridApi.redrawRows({ rowNodes: rowNodes });
      setTimeout(() => {
        this.gridApi.setFocusedCell(lastCellCliked.rowIndex, lastCellCliked.column.getColId());
      }, 10);
    }
  }

  onFilerChanged($event: FilterChangedEvent<any,any>) {
    StaticFields.precodeScreenData.filters = this.gridApi.getFilterModel();
  }

  onGridPreDestroyed($event: GridPreDestroyedEvent<any,any>) {
    if (!$event.context.showGrouped)
      StaticFields.precodeScreenData.colState = $event.api.getColumnState();
  }

  onCellKeyDown($event: import("ag-grid-community").CellKeyDownEvent<any,any>|import("ag-grid-community").FullWidthCellKeyDownEvent<any,any>) {
    let e = $event.event as unknown as KeyboardEvent;
    if (e.key === 'f' && e.ctrlKey) {
      this.showFindReplace = true;
    }    
  }

  onSessionSelected($event: ListboxChangeEvent) {
    if (!$event.value)
      return;
    // if (($event.originalEvent.target as any).nodeName === 'IMG')
    //   this.deleteSession($event.value.name);
    // else {
      this.sessionName = $event.value.name;
      this.existingGridColors.splice(0);
      this.rowData.splice(0);
      this.collapseResultPanel = true;
      this.collapseSearchPanel = false;
      this.loader.setMessage('Loading...');
      this.getUserGridPreferences(this.sessionName, true, false);
    // }
  }

  openColorSelector() {
    this.showColorSelector = true;
    setTimeout(() => {
      let listbox: string = '';
      const colorLb = document.getElementById("lbMainColor") as HTMLElement;
      this.existingGridColors.forEach((c: string) => {
        listbox +='<option style="height: 30px; background-color: ' + c + ';" value="' + c + '"></option>';
      });
      listbox +='<option style="height: 30px; font-size: 17px; padding: 5px;" value="No Fill">No Fill</option>';
      colorLb.innerHTML = listbox;
    }, 10);
  }

  getContextMenuItems(params: any) {
    var result = [
      {
        name: 'Remove Row',
        disabled: !(params.value && params.api.getSelectedRows().length > 0),
        action: function () { params.context.removeRows(params,"row"); }
      },
      {
        name: 'Remove Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 0),
        action: function () { params.context.removeRows(params,"selected"); }
      },
      {
        name: 'Bulk Edit',
        disabled: !(params.value && params.api.getSelectedRows().length > 1 && params.api.getAllDisplayedColumns().map((c: any) => c.colId).findIndex((c: string) => c.includes('_Comments')) > -1),
        action: function () { params.context.editMultipleRows(); }
      },
      'separator',
      {
        name: 'Fill Up',
        disabled: !(params.value && !isNaN(params.node.id) && Number(params.node.id) > Number(params.node.parent.childrenAfterGroup[0].id) && (params.column.colId.includes('_Comments') || params.column.colId.includes('stage') || params.column.colId.includes('MetaData'))),
        action: function () { params.context.fill(params, "up"); },
      },
      {
        name: 'Fill Down',
        disabled: !(params.value && !isNaN(params.node.id) && Number(params.node.id) < Number(params.node.parent.childrenAfterGroup[params.node.parent.childrenAfterGroup.length - 1].id) && (params.column.colId.includes('_Comments') || params.column.colId.includes('stage') || params.column.colId.includes('MetaData'))),
        action: function () { params.context.fill(params, "down"); }
      },
      {
        name: 'Fill Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 1 && (params.column.colId.includes('omments') || params.column.colId.includes('stage') || params.column.colId.includes('etadata'))),
        action: function () { params.context.fill(params, "selected"); }
      },
      'separator',
      {
        name: 'Report - Experiment Detail',
        disabled: !(params.value && params.api.getSelectedRows().length > 0),
        action: function () { params.context.displayReport('experiment'); }
      },
      {
        name: 'Report - PStage Detail',
        disabled: !(params.value && params.api.getSelectedRows().length > 0),
        action: function () { params.context.displayReport('pstage'); }
      },
      'separator',
      {
        name: 'Set Row Color',
        action: function () { params.context.setColor(params, 'row'); }
      },
      {
        name: 'Set Column Color',
        action: function () { params.context.setColor(params, 'col'); }
      },
      {
        name: 'Set Cell Color',
        action: function () { params.context.setColor(params, 'cell'); }
      },
      'separator',
      'copy',
      'copyWithHeaders',
      {
        name: 'Export',
        icon:'<i class="fa fa-upload"></i>',
        action: function () { params.context.export(false); }
      },
      {
        name: 'Export Selected rows',
        icon:'<i class="fa fa-upload"></i>',
        action: function () { params.context.export(true); }
      }
      
    ]
    return result;
  }

  toggleFavorites(event: any) {
     if (event.checked) {
      const favorites = StaticFields.precodePreference.favouritePrograms.split(",");
      this.selectedInclusions = this.inclusions.filter(p => favorites.indexOf(p.code) !== -1);
     } else {
      const selInclusions = this.inclusions.filter(p => StaticFields.precodePreference.include.split(",").indexOf(p) !== -1);
      this.selectedInclusions = this.inclusions.filter(i => selInclusions.includes(i.code));
     }
  }

  export(selected? : boolean) {
    this.commonService.downloadExcelFile(this.agGrid, selected ,false);
  }

  editMultipleRows() {
    this.bulkCusMetaData = this.bulkComments = '';
    this.bulkMetaData.splice(0);
    this.editCusMetadataChecked = this.editCommentsChecked = this.editMetadataChecked = false;
    if (this.bulkEditStages.length > 0)
    this.selBulkEditStage = this.bulkEditStages[0];
    this.showBulkEditDialog = true;
  }

  removeRows(params: any, type: string) {
    let node = params.node.parent;
    let children = node.childrenAfterGroup;
    if(type == "row"){
      const index = this.rowData.findIndex((ele : any) => ele.id == params.node.data.id);
      this.rowData.splice(index,1);
      this.gridApi!.applyTransaction({ remove: [params.node.data] })!;
    } else {
      const selectedNodes = this.gridApi.getSelectedRows();
      this.gridApi!.applyTransaction({ remove: selectedNodes })!;
      this.rowData = this.rowData.filter((ele : any) => {
        return selectedNodes.indexOf(ele) === -1;
      })
    }
    StaticFields.precodeScreenData.data = this.rowData;
    // params.api.refreshCells(params);
  }

  displayReport(type: string) {
    const selectedNodes = this.gridApi.getSelectedRows();
    const geIds = selectedNodes.map((ele: any) => ele.geId).join();
    this.ref = this.dialogService.open(CommonreportmodalComponent, {
      data : {
        url: type == 'experiment' ? 'GetExperimentDataByGEs' : type == 'pstage' ? 'GetPStageDataByGEs' : 'GetLineGraph',  
        geIds : geIds,
        crop : this.selectedCrop,
        report : true
      },
      header: type == 'experiment' ? 'Experiment Detail Report' : type ==  'pstage' ? 'PStage Detail Report' : 'Linegraph Detail Report',
      width: '95%',
      height: '70%',
      styleClass: 'reportModal',
      appendTo: 'body',
      modal: true,
      closable : true,
    })
  }

  fill(params: any, fillType: string) {
    if (fillType === "down" || fillType === "up") {
      const children = params.node.parent.childrenAfterGroup;
      let idx = children.findIndex((c: any) => c.id === params.node.id);
      children.forEach((rowNode: any) => {
        if (fillType === "up" ? idx > rowNode.childIndex : idx < rowNode.childIndex) {
          //const existingVal = this.rowDataCopy.find(r => r.id === rowNode.data.id)[params.column.colId];
          if (rowNode.data && ((params.column.colId.includes('stage') && this.stages.includes(params.value)) || params.column.colId.includes('omments') || params.column.colId.includes('etadata'))) {
            rowNode.data[params.column.colId] = params.value;
          }
        }
      });
    } else if (fillType === "selected") {
      params.api.getSelectedRows().forEach((rowNode: any) => {
        //const existingVal = this.rowDataCopy.find(r => r.id === rowNode.data.id)[params.column.colId];
        if (rowNode && ((params.column.colId.includes('stage') && this.stages.includes(params.value)) || params.column.colId.includes('omments') || params.column.colId.includes('etadata'))) {
          rowNode[params.column.colId] = params.value;
        }
      });
    }
    params.api.refreshCells(params);
  }

  setColor(params: any, type: string) {
    const selRows : any[] = [];
    const selCells = params.api.getCellRanges();
    let uniqueCols: string[] = [];
    const grpColCount = params.api.getRowGroupColumns().length;
    let node = params.node.parent;
    for (let i = 0; i < grpColCount; i++) {
      node = node.parent;
    }
    if (type === 'col') {
      const cols = selCells.map((c:any) => c.columns).flatMap((cols: any) => cols).map((col: any) => col.colId);
      uniqueCols = cols.filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
      node.allLeafChildren.forEach((rowNode: any) => {
        selRows.push(rowNode);
        uniqueCols.forEach((colId: string) => {
          rowNode.data[`${colId}_cellColor`] = this.selColor;
        });
      });
    } else {
      selCells.forEach((r : any) => {
        const startIdx = r.startRow.rowIndex;
        let endIdx = r.endRow.rowIndex;
        let idx = startIdx;
        if (endIdx < startIdx) {
          idx = endIdx;
          endIdx = startIdx;
        }
        while (idx <= endIdx) {
          var row = params.api.getDisplayedRowAtIndex(idx);
          if (type === 'row') {
            Object.getOwnPropertyNames(row.data).forEach((col) => {
              row.data[`${col}_cellColor`] = this.selColor;
              if (!uniqueCols.includes(col))
                uniqueCols.push(col);
            });
          } else {
            r.columns.forEach((c : any) => {
              row.data[`${c.colId}_cellColor`] = this.selColor;
              if (!uniqueCols.includes(c.colId))
                uniqueCols.push(c.colId);
            });
          }
          selRows.push(row);
          idx++
        }
      });
    }
    const rowData = node.allLeafChildren.map((d: any) => d.data);
    uniqueCols.forEach(col => {
      params.api.getColumnFilterInstance(col).then((filter: any) => {
        const cusFilterLb = filter.filters[0].eFilterlb ? filter.filters[0].eFilterlb : filter.filters[1].eFilterlb;
        let listbox: string = '';
        const colName = `${col}_cellColor`;
        const dataWithColor = rowData.filter((o: any) => o && o[colName]);
        dataWithColor.map((d: any) => d[colName]).filter((value: string, index: number, array: string[]) => array.indexOf(value) === index).forEach((c: string) => {
            listbox +='<option style="background-color: ' + c + ';" value="' + c + '"></option>';
        });
        listbox +='<option value="No Fill">No Fill</option>';
        cusFilterLb.innerHTML = listbox;
      });
    });
    params.api.redrawRows({ rowNodes: selRows });
  }
  
  //Find text in ag-grid
  find(clearFoundDataWhenDone: boolean) {
    let found = false;
    let rowNodes: any = [];
    let focusedCell = this.gridApi.getFocusedCell();
    if (focusedCell || this.lastFoundCell) {
      const rowIdx = this.lastFoundCell ? this.lastFoundCell.rowIndex : focusedCell.rowIndex;
      const field = this.lastFoundCell ? this.lastFoundCell.field : focusedCell.column.colId;
      let lastFoundObj: any;
      if (this.foundCell.length > 0) {
        lastFoundObj = this.foundCell[this.foundCell.length - 1];
        if (!(lastFoundObj.rowIndex == rowIdx && lastFoundObj.field == field)) {
          this.foundCell = [];
          this.foundIndex = focusedCell.rowIndex;
        }
      }
    }
    this.gridApi.forEachNode((node: any) => {
      rowNodes.push(node);
    });
    for (let i=this.foundIndex; i < rowNodes.length ; i++) {
      let node = rowNodes[i];
      var rowObj = node.data;
      found = false;
      for (var key in rowObj) {
        if (this.matchWordChecked && rowObj[key] && (this.matchCaseChecked ? rowObj[key].toString() === this.findText : rowObj[key].toString().toUpperCase() === this.findText.toUpperCase()) 
            && !this.foundCell.find(c => c.field === key && c.rowIndex === node.rowIndex)) {
          found = true;
          this.foundCell.push({ 'field': key, 'rowIndex': node.rowIndex});
          break;
        } else if (!this.matchWordChecked && rowObj[key] && (this.matchCaseChecked ? rowObj[key].toString().includes(this.findText) : rowObj[key].toString().toUpperCase().includes(this.findText.toUpperCase())) 
            && !this.foundCell.find(c => c.field === key && c.rowIndex === node.rowIndex)) {
          found = true;
          this.foundCell.push({ 'field': key, 'rowIndex': node.rowIndex});
          break;
        }
      }
      if (found) {
        break;
      }
    }
    if (found) {
      this.lastFoundCell = this.foundCell[this.foundCell.length-1];
      if (clearFoundDataWhenDone) {
        this.gridApi.ensureIndexVisible(this.lastFoundCell.rowIndex, 'middle');
        this.gridApi.ensureColumnVisible(this.lastFoundCell.field);
        this.gridApi.setFocusedCell(this.lastFoundCell.rowIndex, this.lastFoundCell.field);
      }
      this.found = true;
    } else {
      if (this.foundCell.length === 0)
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Found', detail: 'No match found' });
      else if (clearFoundDataWhenDone)
        this.customMessageService.showMessage({'severity': 'info', summary: 'No more matches', detail: 'Reached end of Grid. Click find to search again.' });
      this.foundIndex = 0;
      if (clearFoundDataWhenDone)
        this.foundCell = [];
      this.found = false;
      this.lastFoundCell = null;
    }
  }
  
  //Replace text in ag-grid
  replace(isReplaceAll: boolean) {
    if (this.found || isReplaceAll) {
      let focusedCell: any;
      var cell: any;
      let rowNodes: any[] = [];
      focusedCell = this.gridApi.getFocusedCell();
      if (focusedCell) {
        cell = { rowIndex: focusedCell.rowIndex, field: focusedCell.column.colId };
      } else {
        cell = this.foundCell[this.foundCell.length - 1];
      }
      this.gridApi.forEachNode((node: any) => {
        rowNodes.push(node);
      });
      var rowNode: any;
      var newCellValue: any;
      if (isReplaceAll) {
        this.foundCell = [];
        this.found = true;
        while (this.found) {
          this.find(false);
        }
        let allfoundCell = this.foundCell;
        let updatedCnt = 0;
        for (var i = 0; i < allfoundCell.length; i++) {
          cell = allfoundCell[i];
          const foundCol = this.gridApi.getColumnDefs().find((c: any) => c.field === cell.field);
          rowNode = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
          if (rowNode && foundCol && foundCol.editable) {
            const val = this.gridApi.getValue(cell.field, rowNode)
            const currentCellValue = val ? val.toString() : '';
            if (this.matchCaseChecked)
              newCellValue = currentCellValue.replace(this.findText, this.replaceText);
            else {
              let pattern = new RegExp(this.findText, 'gi');
              newCellValue = currentCellValue.replace(pattern, this.replaceText);
            }
            if (typeof rowNode.data[cell.field] === 'number' && Number(newCellValue))
              newCellValue = Number(newCellValue);
            rowNode.setDataValue(cell.field, newCellValue);
            updatedCnt++;
          }
        }
        this.foundCell = [];
        this.found = false;
        if (allfoundCell.length > 0)
          this.customMessageService.showMessage({'severity': 'info', summary: 'Replaced', detail: `Replaced ${updatedCnt} matches` });
      } else {
        if (this.gridApi.getColumnDefs().find((c: any) => c.field === cell.field).editable) {
          rowNode = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
          const currentCellValue = this.gridApi.getValue(cell.field, rowNode).toString();
          if (this.matchCaseChecked)
            newCellValue = currentCellValue.replace(this.findText, this.replaceText);
          else {
            let pattern = new RegExp(this.findText, 'gi');
            newCellValue = currentCellValue.replace(pattern, this.replaceText);
          }

          if (newCellValue != rowNode.data[cell.field].toString()) {
            if (typeof rowNode.data[cell.field] === 'number' && Number(newCellValue))
              newCellValue = Number(newCellValue);
            rowNode.setDataValue(cell.field, newCellValue);
          }
        }
        this.find(true);
      }
    }
  }

  addGeAssessments() {
    this.ref = this.dialogService.open(GeassessmentmodalComponent, {
      data : {crops : this.crops, selectedCrop :this.selectedCrop, selectedAssessmentsList :this.geAssessmentsList },
      header: 'Search Germplasm Assessments',
      width: '95%',
      height: '90vh',
      styleClass: 'geModal',
      appendTo: 'body',
      closable : false
    });

    this.ref.onClose.subscribe((result : any) => {
      if (result) {
        this.showGeTraitGrid = false;
        const newAssessmentMembers = result.assessmentMembers.map((member : any) => {
          const { description: traitDescription, ...rest } = member;
          return { traitDescription, ...rest }
        });
        newAssessmentMembers.forEach((ele : any) => ele.selected = this.geAssessmentsList.find((e: any) => e.traitId == ele.traitId) ? this.geAssessmentsList.find((e: any) => e.traitId == ele.traitId).selected : true);
        this.geAssessmentsList = [...newAssessmentMembers];
        this.geAssessmentsList = this.geAssessmentsList.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.traitId === value.traitId
          ))
        ); 
        this.selectedAssessments = this.geAssessmentsList.filter(m => m.selected); 
        setTimeout(() => {
          this.showGeTraitGrid = true;
        }, 10);                      
      }
    });
  }

  saveBulkEdit() {
    const allCols = this.gridApi.getAllDisplayedColumns().map((c: any) => c.colId);
    const commentCol = allCols.find((c: any) => c.toUpperCase().includes(this.selBulkEditStage) && c.includes('_Comments'));
    const metadataCol = allCols.find((c: any) => c.toUpperCase().includes(this.selBulkEditStage) && c.includes('_MetaData'));
    const cusMetadataCol = allCols.find((c: any) => c.toUpperCase().includes(this.selBulkEditStage) && c.includes('_CustomMetaData'));
    const selRows = this.gridApi.getSelectedRows();
    selRows.forEach((rowNode: any) => {
      rowNode.hasChanges = true;
      if (this.editCommentsChecked)
        rowNode[commentCol] = this.bulkComments;
      if (this.editCusMetadataChecked)
        rowNode[cusMetadataCol] = this.bulkCusMetaData;
      if (this.editMetadataChecked)
        rowNode[metadataCol] = this.bulkMetaData;
    });
    this.gridApi.refreshCells(selRows);
    this.showBulkEditDialog = false;
  }

  onRowClick(event : any) {
    event['selected'] = !event['selected'];
  }

  removeGeAssessments() {
    this.geAssessmentsList = this.geAssessmentsList.filter( ele => !this.selectedAssessments.find((item : any) => item.traitId == ele.traitId));
    this.selectedAssessments.splice(0);
  }

  showPStageDialog() {
    this.showPStageModal = true;
    this.pStageDataCopy = JSON.parse(JSON.stringify(this.pStageData));
  }

  savePStageData(saveStage: boolean) {
    if (!saveStage){
      this.showPStageModal = false;
      return;
    }
    if (this.pStageData.find(ele =>( ele.type !== "" && (ele.name.length === 0 || ele.year.length == 0)) || (ele.type == "" && (ele.name.length === 0 && ele.year.length == 0)))) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `There are ${this.pStageData.length - this.validPstageData} row(s) with incomplete data found. Do you want to save remaining ${this.validPstageData} row(s).`,      
        accept: () => {
          this.showPStageGrid = false;
          this.pStageData = this.pStageData.filter(ele => ele.type !== "" && (ele.name.length > 0 && ele.year.length > 0));
          this.pStageData.forEach((ele : any) => ele.selected = this.pStagesList.find((e: any) => e.id == ele.id) ? this.pStagesList.find((e: any) => e.id == ele.id).selected : true);
          this.pStagesList = JSON.parse(JSON.stringify(this.pStageData));
          this.pStagesList.forEach((ele : any) => {ele.name = ele.name.join(","); ele.year =ele.year.join(",");});
          this.selectedPStages = this.pStagesList.filter(p => p.selected);
          this.showPStageModal = false;
          setTimeout(() => {
            this.showPStageGrid = true;
          }, 10);
        }
      });
    } else {
      this.showPStageGrid = false;
      this.showPStageModal = false;
      this.pStageData.forEach((ele : any) => ele.selected = this.pStagesList.find((e: any) => e.id == ele.id) ? this.pStagesList.find((e: any) => e.id == ele.id).selected : true);
      this.pStagesList = JSON.parse(JSON.stringify(this.pStageData))
      this.pStagesList.forEach((ele : any) => {ele.name = ele.name.join(","); ele.year =ele.year.join(",");});
      this.selectedPStages = this.pStagesList.filter(p => p.selected);
      setTimeout(() => {
        this.showPStageGrid = true;
      }, 10);
    }
  }


  addPStageRow() {
    const object : any = {'id' : this.pStageCounter,'type':"",'name':[],'year': [], crop : this.selectedCrop.Name,selected : false};
    this.pStageCounter++;
    this.pStageData = [object,...this.pStageData];
  }

  onPStageGridReady($event: GridReadyEvent<any>) {
    this.pStageGridApi = $event.api;
  }

  onApexGridReady($event: GridReadyEvent<any>) {
    this.apexGridApi = $event.api;
  }

  removePStage() {
    this.pStagesList = this.pStagesList.filter( ele => !this.selectedPStages.find((item : any) => item.id == ele.id));
    this.pStageData = this.pStageData.filter( ele => !this.selectedPStages.find((item : any) => item.id == ele.id));
    this.selectedPStages.splice(0);
  }

  onListContextMenu($event: any) {
    this.cm.target = $event.currentTarget;
    this.cm.show($event);
  }

  shareSession(sessionToShare: string) {
    this.adUserData.splice(0);
    this.searchMemberString = "";
    this.sessionToShare = sessionToShare;
    this.showADDialog = true;
  }

  searchFromAd() {
    let params = new HttpParams();
    params = params.append("filter", this.searchMemberString);
    this.apiService.getData('SearchUsersInAD', params).subscribe((res: any) => {
      if (res.httpStatusCode == 200) {
        if (res.data.length > 0)
          this.adUserData = res.data;
        else
          this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Found', detail: 'No records found for your search.'});
      }
    });
  }

  shareWithADUsers() {
    const userEmails = this.getUserSelectedRows.map((m: any) => m.mail);
    const submitData = { users: userEmails, session: this.sessionToShare, appUserId: StaticFields.appUserId, description: this.shareDescription };
    this.apiService.putData('ShareSession', submitData).subscribe((res: any) => {
      if (res.httpStatusCode == 200) {
        this.customMessageService.showMessage({'severity': 'success', summary: 'Session Shared', detail: res.message });
        this.sessions.find((s: any) => s.name === this.sessionToShare).count = userEmails.length;
      }
      this.sessionToShare = "";
      this.showADDialog = false;
    });
  }

  onUserGridReady($event: GridReadyEvent<any>) {
    this.userGridApi = $event.api;
  }

  getAPEXSessionsForUser() {
    const selectedGes = this.gridApi.getSelectedRows();
    const selectedCrop = StaticFields.crops.find(ele => ele.Name == this.selectedCrop.Name);
    const invalidGes = selectedGes.filter((ele : any) => !selectedCrop?.MaterialType?.includes(ele?.material_Type))
    if (invalidGes.length == selectedGes.length) {
      this.customMessageService.showMessage({'severity': 'warn', summary: 'Invalid Material Type(s)', detail: `Crop "${this.selectedCrop.Name}" has the following material type(s): "${selectedCrop?.MaterialType}". Selected row(s) do not match the material type.`});
    } else if (invalidGes.length > 0 && invalidGes.length != selectedGes.length) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `Crop "${this.selectedCrop.Name}" has the following material type(s): "${selectedCrop?.MaterialType}". ${invalidGes.length} row(s) do not match the material type and will be ignored. Do you want to proceed?`,  
        accept : () => {
          this.openApexJobDialog();      
        }
      });
    } else if (invalidGes.length == 0){
        this.openApexJobDialog();          
    }
  }

  openApexJobDialog() {
    if (this.apexData && this.apexData.length > 0 && this.apexData[0].crop.toUpperCase() === this.selectedCrop.Name.toUpperCase()){
      this.showAPEXJobs = true;
    }            
    else {
      let params = new HttpParams();
      params = params.append("crop", this.selectedCrop.Name);
      params = params.append('userName', StaticFields.userInfo.onPremisesSamAccountName);
      this.apiService.getData('GetJobsByCropAndUser', params).subscribe((res: any) => {
        if (res.httpStatusCode == 200) {
          if (res.data.length > 0) {
            this.apexData = res.data;
            this.showAPEXJobs = true;
          } else
            this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Found', detail: `No public Session found for crop ${this.selectedCrop.Name}.`});
        }
      });
    }
  }

  submitAPEXData() {
    const selectedApexJob = this.apexGridApi.getSelectedRows()[0];
    const selectedGes = this.gridApi.getSelectedRows();
    const ges : any[] = [];
    const selectedCrop = StaticFields.crops.find(ele => ele.Name == this.selectedCrop.Name);
    selectedGes.forEach((ele : any) => {
      if (selectedCrop?.MaterialType?.includes(ele?.material_Type)) {
        let obj : any = new Object();
        obj['GeId'] = ele.geId;
        obj['Name'] = ele.cvn;
        obj['Impute'] = 1;
        obj['Comment'] = "From GEVL";
        ges.push(obj);
      }      
    });
    if (ges.length > 0) {
      const apexGeJobsToSubmit = {
        JobId : selectedApexJob.id,
        Ges: ges
      };
      this.apiService.postData('SubmitJobsToApex', apexGeJobsToSubmit, true, true).subscribe((res: any) => {
        if (res.httpStatusCode == 200) {
          this.customMessageService.showMessage({'severity': 'warn', summary: 'GE(s) Submitted ', detail: 'Selected GE(s) have been submitted to APEX Successfully.'});
        }
      });
      this.showAPEXJobs = false;
      this.gridApi.deselectAll();
      this.gridApi.clearFocusedCell();
    } 
  }

  // expandByGens() {

  // }
}




