import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';

export class ColorFilter implements IFilterComp {
    filterParams!: IFilterParams;
    filter: string[] = [];
    gui!: HTMLDivElement;
    eFilterlb: any;

    init(params: IFilterParams) {
        this.filterParams = params;
        this.filter.splice(0);
        this.setupGui(params);
    }

    // not called by AG Grid, just for us to help setup
    setupGui(params: IFilterParams) {
        this.gui = document.createElement('div');
        this.gui.innerHTML = `<div style="margin-left: 5px; margin-bottom: 5px">
                <div style="font-weight: bold; margin-bottom: 3px;">Filter By Color</div>
                <div>
                    <select id="lbColor" name="Colors" size="4" style="width: 7rem;" multiple></select>
                </div>
            </div>
        `;

        const listener = (event: any) => {
            const options = event.target.options;
            this.filter.splice(0);
            for (let i=0; i < options.length; i++) {
                if (options[i].selected) {
                  this.filter.push(options[i].value);
                }
            }
            params.filterChangedCallback();
        };

        this.eFilterlb = this.gui.querySelector('#lbColor');
        let listbox: string = '';
        const colName = `${params.column.getColId()}_cellColor`;
        const rowData = (params.rowModel as any).rowsToDisplay.map((d: any) => d.data);
        const dataWithColor = rowData.filter((o: any) => o && o[colName]);
        dataWithColor.map((d: any) => d[colName]).filter((value: string, index: number, array: string[]) => array.indexOf(value) === index).forEach((c: string) => {
            listbox +='<option style="background-color: ' + c + ';" value="' + c + '"></option>';
        });
        listbox +='<option value="No Fill">No Fill</option>';
        this.eFilterlb.innerHTML = listbox;
        this.eFilterlb.addEventListener('change', listener);
    }

    getGui() {
        return this.gui;
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        if (this.filter.length === 0)
            return true;
        const node = params;
        const colName = `${this.filterParams.column.getColId()}_cellColor`;
        const value = node.data[colName];
        if ((value && this.filter.includes(value) || (this.filter.includes('No Fill') && !value))) {
            return true;
        }
        return false;
    }

    isFilterActive() {
        return this.filter.length > 0;
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }
        return { value: this.filter };
    }

    setModel(model: any) {
        if (model == null) {
            var options = this.eFilterlb && this.eFilterlb.options;
            for (let i=0; i < options.length; i++) {
                options[i].selected = false;
            }
            this.filter.splice(0);
        } else {
            // custom implementation may be required 
        }
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        if (!params?.suppressFocus) {
            // focus the input element for keyboard navigation
            this.eFilterlb.focus();
        }
    }
}