import { PrimeNgModule, PrimeNgProviders } from './primeng-modules';
import { LoadingComponent } from '../components/loading/loading.component';
import { CustomHeader } from '../components/customHeader';
import { WINDOW_PROVIDERS } from '../windowprovider';
import { AutoCompleteComponent } from '../autoComplete';
import { SetCustomColorComponent } from '../setCustomColor';
import { SearchGeAssessmentsHeader } from '../searchGeAssessmentsHeader';
import { ManageCropModalHeaderComponent } from '../cropManageHeader';
import { ReportModalHeaderComponent } from '../reportModalHeader';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [PrimeNgModule, BrowserAnimationsModule, BrowserModule, FormsModule],
    declarations:[
        LoadingComponent,
        CustomHeader,
        AutoCompleteComponent,
        SetCustomColorComponent,
        SearchGeAssessmentsHeader,
        ManageCropModalHeaderComponent,
        ReportModalHeaderComponent
    ],
    exports:[PrimeNgModule, LoadingComponent],
    providers:[WINDOW_PROVIDERS, PrimeNgProviders]
    
})
export class SharedModule {}