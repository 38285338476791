<h3 style="font-weight: bold;padding-left: 10px;font-style: normal;color: #007ad9">Master Data</h3>
<div class="grid" style="margin-bottom: 10px;">
    <div style="margin-left: 20px; margin-top: 10px;">
        <p-dropdown [options]="dataTypes" [style]="{ 'width': '140px'}" placeholder="Select Type" [(ngModel)]="SelType" optionLabel="name"></p-dropdown>
    </div>
    <div class="col" style="display: flex; justify-content: flex-end;">
        <p-button (onClick)="addRow()"  id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Add Row"> Add</p-button>
        <p-button (onClick)="saveChanges()" id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Update Data" [disabled]="updatedData.length === 0"> Update</p-button>
        <p-button (onClick)="reset()" id="buttonStyle"  data-toggle="tooltip" data-placement="top" title="Cancel Changes" [disabled]="updatedData.length === 0"> Cancel</p-button>
    </div>
</div>
<p-confirmDialog [style]="{'width' : '450px', 'height' : '230px'}" appendTo="body">
</p-confirmDialog> 
<ag-grid-angular *ngIf="showGrid" id="agGrid" (gridReady)="onGridReady($event)"
    style="height: 75vh; width: 100%; " [rowSelection]="'multiple'" (cellValueChanged)="onCellValueChanged($event)"
    class="ag-theme-balham" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData" 
    [sideBar]="{ toolPanels: ['columns', 'filters'] }" [animateRows]="true"></ag-grid-angular>
