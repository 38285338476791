import { Injectable } from "@angular/core"; 
import { Subject } from "rxjs";

@Injectable({ 
    providedIn: "root" 
}) 

export class CropUpdateService {
    private resetData = new Subject<any>();
    resetData$ = this.resetData.asObservable();

    resetDataCommunicate(data : any) {
        this.resetData.next(data);
    }
}