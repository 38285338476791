<p-panel [toggleable]="true" [collapsed]="collapseSearchPanel" header="Search Criteria" >
    <ng-template pTemplate="icons">
        <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(selectedCrop && selectedLevel && selectedInclusions)" title="Search Data" (onClick)="SearchData()"> Retrieve</p-button>
        <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!allowReset" title="Cancel Changes" (onClick)="resetSearch()"> Reset</p-button>
    </ng-template>
    <div class="grid nested-grid">
        <div class="col-10">
            <div class="grid flex flex-row flex-wrap ml-2 mt-0.5 gap-4" style="padding-top: 34px;">
                <p-floatLabel>
                    <p-dropdown id="crop" [options]="crops" [(ngModel)]="selectedCrop" (ngModelChange)="changeCrop(false)" [filter]="true" optionLabel="Name" placeholder="Select" />
                    <label for="crop">Crop* </label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-dropdown id="level" [options]="levels" [(ngModel)]="selectedLevel" (ngModelChange)="changeLevel()" optionLabel="name" placeholder="Select" />
                    <label for="level">Level* </label>
                </p-floatLabel>
                <span>
                    <span *ngIf="this.selectedLevel?.name == 'Program(s)'" >
                        <span style="display: block;margin-bottom: 10px;margin-top: -35px;" >
                            <p-checkbox [(ngModel)]="favProgramsChecked" label="My Favorite Programs" [binary]="true" (onChange)="toggleFavorites($event)" inputId="binary" />
                        </span>
                        <span>
                            <p-multiSelect [options]="inclusions" [(ngModel)]="selectedInclusions" placeholder="Select" optionLabel="code" [resetFilterOnHide]="true">
                                <ng-template let-include pTemplate="include">
                                    <div data-toggle="tooltip" data-placement="top" title="{{include.desc}}" style="width: 100%;">{{ include.code }}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </span>
                    </span>
                    <p-floatLabel *ngIf="this.selectedLevel?.name !== 'Program(s)'">
                        <p-multiSelect [options]="inclusions" [(ngModel)]="selectedInclusions" placeholder="Select" optionLabel="code" [resetFilterOnHide]="true">
                            <ng-template let-include pTemplate="include">
                                <div data-toggle="tooltip" data-placement="top" title="{{include.desc}}" style="width: 100%;">{{ include.code }}
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <label for="include">Include*</label>
                    </p-floatLabel>
                </span>
                
                <p-floatLabel>
                    <p-multiSelect id="stage" [options]="stages" [(ngModel)]="selectedStages" placeholder="Select" [resetFilterOnHide]="true" />
                    <label for="stage">Stage</label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-multiSelect id="years" [options]="years" [(ngModel)]="selectedYears" placeholder="Select" [resetFilterOnHide]="true" />
                    <label for="years">Years</label>
                </p-floatLabel>
                <p-floatLabel id="gensDropDown">
                    <p-dropdown id="genToFetch" [options]="genList" [(ngModel)]="genToFetch" optionLabel="name" />
                    <label for="genToFetch" style="top:-1.5rem;width: 160px;">Generations To Summarize</label>
                </p-floatLabel>
            </div>
            <div style="margin: 10px;">
                <label style="text-transform: capitalize;font-weight: 800;width: 100%;">Expand By :</label>
            </div>
            <div class="flex flex-row flex-wrap ml-2 pb-4 gap-6">
                <div>            
                    <p-checkbox [(ngModel)]="geLineageChecked" label="GE Lineage" [binary]="true" inputId="binary" />
                </div>
                <div>
                    <p-checkbox [(ngModel)]="seedInventoryChecked" label="Seed Inventory" [binary]="true" inputId="binary" />
                </div>
                <div><p-checkbox [(ngModel)]="popRequestChecked" label="Pop Request" [binary]="true" inputId="binary" />
                </div>
                <div>
                    <p-checkbox [(ngModel)]="experimentsChecked" label="Experiments" [binary]="true" inputId="binary" />
                </div>
                <div>
                    <p-checkbox [(ngModel)]="apexVoteChecked" label="APEX Vote" [binary]="true" inputId="binary" />
                </div>
            </div>           
        </div>
        <div class="col">
            <label style="margin-right: 10px; text-align: center;">
                Select Session:
            </label>
            <p-listbox [(ngModel)]="selSession" [style]="{ 'max-width': '300px' }" [options]="sessions" (onChange)="onSessionSelected($event)" optionLabel="name" [listStyle]="{'height': '114px'}">
                <ng-template let-session pTemplate="item">
                    <div data-toggle="tooltip" data-placement="top" [title]="session.description ? 'Load Saved Session with description ' + session.description : 'Load Saved Session'" (contextmenu)="onListContextMenu($event)">{{ session.name }}</div>
                    <i *ngIf="session.count > 0" class="pi pi-user-plus" data-toggle="tooltip" data-placement="top" title="Session shared with {{session.count}} user(s)" style="font-size: 14px; margin-left: 7px; margin-bottom: 1px;"></i>
                </ng-template>
            </p-listbox>
            <p-contextMenu #cm [model]="cmItems" aria-modal="true" />
        </div>
        <div class="grid">
            <div class="col-6">
                <label style="font-weight: bold;font-size: large;margin-bottom:3px">Germplasm Assessments ({{selectedAssessments.length}}/{{geAssessmentsList.length}})</label>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Add Germplasm Assessments" [disabled]="selectedCrop == undefined" (onClick)="addGeAssessments()"> Add</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Cancel Changes" [disabled]="selectedAssessments.length === 0" [style]="{'margin-left': '5px'}" (onClick)="removeGeAssessments()"> Remove</p-button>    
                <p-table *ngIf="showGeTraitGrid" [value]="geAssessmentsList"  selectionMode="multiple" [(selection)]="selectedAssessments" dataKey="traitId"  [tableStyle]="{ 'width': '44rem', 'margin-top':'10px' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 2rem"><p-tableHeaderCheckbox /></th>
                            <th>Id</th>
                            <th>Assessment Name</th>
                            <th>Assessment Description</th>
                            <th>Source</th>
                            <th>Region(s)</th>
                            <th>Year(s)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td (click)="onRowClick(rowData)">
                                <p-tableCheckbox [value]="rowData" />
                            </td>
                            <td>{{ rowData.traitId }}</td>
                            <td>{{ rowData.traitName }}</td>
                            <td>{{ rowData.traitDescription }}</td>
                            <td>{{ rowData?.source }}</td>
                            <td>{{ rowData?.aois }}</td>
                            <td>{{ rowData?.years }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>  
            <div class="col-6">
                <label style="font-weight: bold;font-size: large;margin-bottom:3px">PStage Settings({{selectedPStages.length}}/{{pStageData.length}})</label>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Add PStages"  (onClick)="showPStageDialog()"> Add</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Remove Selected PStages" [disabled]="selectedPStages.length === 0" [style]="{'margin-left': '5px'}" (onClick)="removePStage()"> Remove</p-button>    
                <p-table *ngIf="showPStageGrid" [value]="pStagesList"  selectionMode="multiple" [(selection)]="selectedPStages" dataKey="id" [tableStyle]="{ 'width': '44rem', 'margin-top':'10px' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 2rem"><p-tableHeaderCheckbox /></th>
                            <th>Crop</th>
                            <th>Type</th>
                            <th>Name(s)</th>
                            <th>Year(s)</th>                        
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td (click)="onRowClick(rowData)">
                                <p-tableCheckbox [value]="rowData" />
                            </td>
                            <td>{{ rowData.crop }}</td>
                            <td>{{ rowData.type }}</td>
                            <td>{{ rowData.name }}</td>
                            <td>{{ rowData?.year }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>           
        </div>
    </div>
    <p-dialog [(visible)]="showADDialog" [draggable]="false" [baseZIndex]="10000" [modal]="true" [dismissableMask]="false" [style]="{ 'width': '800px','height': '500px' }" appendTo="body">
        <p-header>
            <div class="row">
                <span style="display: block;">Share "{{ sessionToShare }}"</span>
                <label style="color: grey; font-size: 14px; margin-top: -5px;">Multiple users can be searched with comma as delimiter</label>
                <div class="col-7" style="margin-top: -10px;">
                    <input type="text" [(ngModel)]="searchMemberString" placeholder="Search by email, display name or user name" pInputText (keyup.enter)="searchFromAd()" 
                    [style]= "{'width': '100%', 'height': '31px', 'margin-top' : '3px', 'margin-right': '5px'}">
                </div>
                <div class="col" style="text-align: end; margin-right: 45px; margin-top: -10px;">
                    <button type="button" (click)="searchFromAd()" [disabled]="!(searchMemberString)" pButton style="margin-right: 5px;"
                    data-toggle="tooltip" data-placement="top" title="Search for users in AD">Search</button>
                    <button type="button" (click)="shareWithADUsers()" [disabled]="!(getUserSelectedRows && getUserSelectedRows.length > 0)" pButton
                      data-toggle="tooltip" data-placement="top" title="Share with selected users and close">Share & Close</button>
                  </div>
            </div>
        </p-header>
        <div style="margin-top: -10px;" *ngIf="adUserData.length > 0">
            <p-floatLabel>
                <input id="shareDesc" type="text" [(ngModel)]="shareDescription" pInputText [style]= "{'width': '100%', 'height': '31px'}">
                <label for="shareDesc">Add a description to share</label>
            </p-floatLabel>
            
            <div style="margin-top: 5px;">
                <ag-grid-angular
                    style="height: 320px;" (gridReady)="onUserGridReady($event)" [sideBar]="{ toolPanels: ['columns', 'filters'] }" [pivotMode]="false"
                    class="ag-theme-balham" [columnDefs]="userColumnDefs" [defaultColDef]="userDefaultColDef" [animateRows]="true" [rowData]="adUserData" 
                    [suppressRowClickSelection]="true" [rowSelection]="'multiple'"></ag-grid-angular>
            </div>
        </div>
    </p-dialog>
</p-panel>
<div style="height: 3px;"></div>
<p-panel [toggleable]="true" [collapsed]="collapseResultPanel" header="PreCode Information" class="resultsPanel" >
    <ng-template pTemplate="icons">
        <span class="buttonsLayout">
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="rowData.length === 0" title="Save Grid Preferences" (onClick)="saveGridPreferences()"> Save Grid Preferences</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="rowData.length === 0" title="Save data for next session" (onClick)="savePageState()"> Save Session</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="updatedData.length === 0" title="Save" (onClick)="saveChanges()"> Update</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!gridApi || gridApi?.getSelectedRows()?.length === 0" title="Code" (onClick)="navigateToCode()"> Code</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!gridApi || gridApi?.getSelectedRows()?.length === 0" (onClick)="navigateToLineGraph()" title="Line Graph"> Line Graph</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!gridApi || gridApi?.getSelectedRows()?.length === 0" title="Get Apex Sessions" (onClick)="getAPEXSessionsForUser()">Append APEX Session</p-button>            
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!hasFilterApplied" title="Clear Filters" (onClick)="clearFilters()"> Clear Filters</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="updatedData.length === 0" title="Cancel Changes" (onClick)="cancelGridChanges()"> Cancel</p-button>            
        </span>   
    </ng-template>
    <span>
        <!-- <div class="flex flex-row flex-wrap ml-2 mt-2.5  gap-6" style="width: 100%;">
            <div [style]="{'margin-top': '7px'}">
                <p-checkbox [(ngModel)]="expandCheckBox"  label="Expand by selected rows"  [binary]="true" />
            </div>
            <div>
                <label for="genToExpand" style="width: 210px;">Generations To Summarize :</label>
                <p-dropdown id="genToExpand" [options]="genList" [(ngModel)]="genToExpand" [disabled]="genToExpandAll != undefined" optionLabel="name" [showClear]="true"  />
            </div>
               
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="rowData.length === 0" title="Cancel Changes" (onClick)="expandByGens()"> Expand</p-button>            
        </div> -->
        <div style="margin-top: 10px;">   
            <ag-grid-angular *ngIf="rowData.length > 0"  id="agGrid" 
                (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)" (selectionChanged)="onSelectionChanged($event)" (cellKeyDown)="onCellKeyDown($event)"
                style="height: 73vh; width: 100%;font-size: 14px;" class="ag-theme-balham" (filterChanged)="onFilerChanged($event)"
                [rowData]="rowData" [enableRangeSelection]=true [gridOptions]="gridOptions" [groupDisplayType]="groupDisplayType"
                [rowSelection]="'multiple'" [animateRows]="true" [context]="this"></ag-grid-angular>
        </div>
      
    </span>
    <p-dialog [(visible)]="showFindReplace" [draggable]="false" header="Find/Replace" [baseZIndex]="10000" [modal]="false" [dismissableMask]="false" appendTo="body" [position]="'topright'" styleClass="headerPanelSmall">
        <div class="grid" style="margin: 10px; margin-top: 2px;">
            <label style="width: 120px; margin-top: 5px;">Find what</label>
            <input type="text" pInputText [(ngModel)]="findText" style="margin-left: 5px; margin-right: 25px;" />
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!findText" title="Find Next" (onClick)="find(true)" [style]="{width: '97px', display: 'block', padding: '7px'}"> Find Next</p-button>
        </div>
        <div class="grid" style="margin: 10px;">
            <label style="width: 120px; margin-top: 5px;">Replace With</label>
            <input type="text" pInputText [(ngModel)]="replaceText" style="margin-left: 5px; margin-right: 25px;"/>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(findText && replaceText)" title="Replace next match" (onClick)="replace(false)" [style]="{width: '97px', display: 'block', padding: '7px'}"> Replace</p-button>
        </div>
        <div class="grid" style="margin: 10px;">
            <div style="margin-top: 5px;">
                <p-checkbox [(ngModel)]="matchCaseChecked" label="Match case" (onChange)="foundCell=[]" [binary]="true" inputId="binary" />
            </div>
            <div style="margin-left: 50px; margin-right: 65px; margin-top: 5px; margin-bottom: 2px;">
                <p-checkbox [(ngModel)]="matchWordChecked" label="Match Word" (onChange)="foundCell=[]" [binary]="true" inputId="binary" />
            </div>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(findText && replaceText)" title="Replace All" (onClick)="replace(true)" [style]="{padding: '7px'}"> Replace All</p-button>
        </div>
    </p-dialog>
    <p-dialog [(visible)]="showColorSelector" [draggable]="true" header="Select Color" [baseZIndex]="10000" [modal]="true" [dismissableMask]="false" appendTo="body" [position]="'center'" styleClass="headerPanelSmall">
        <div class="grid" style="margin: 10px;">
            <div>
                <label style="margin-bottom: 3px; display: block; width: 150px;">Select From Existing Grid Color(s)</label>
                <select id="lbMainColor" name="Colors" size="5" style="width: 150px;" (change)="updateColor($event)"></select>
            </div>
            <div style="margin-left: 50px; margin-top: 5px;">
                <div class="grid" style="margin-bottom: 5px;">
                    <label style="margin-left: 10px; display: block; margin-right: 30px; margin-top: 2px">Choose Color</label>
                    <p-button id="buttonStyle" title="Ok" (onClick)="updateColor(false)">Ok</p-button>
                </div>
                <p-colorPicker [(ngModel)]="selColor" [inline]="true"></p-colorPicker>
            </div>
        </div>
    </p-dialog>
    <p-dialog *ngIf="showBulkEditDialog" [(visible)]="showBulkEditDialog" [modal]="true" [contentStyle]="{'overflow':'visible'}" [draggable]="false" [baseZIndex]="10000" [dismissableMask]="false" [style]="{ 'width': '800px','height': '350px' }" appendTo="body">
        <p-header class="grid" style="width: 730px; margin-left: 5px;">
            <label style="margin-top: 10px; font-size: larger;">Edit Multiple Rows</label>
            <button pButton type="button" (click)="saveBulkEdit()" style="margin-left: 65%; margin-top: 5px;" [disabled]="!(editCommentsChecked || editMetadataChecked || editCusMetadataChecked)">Apply</button>
        </p-header>
        <div style="color: darkgray; margin-top: -10px; font-size: 15px;">*Add/Update values for muitple rows. To delete existing data, select the column and keep value blank.</div>
        <div style="padding-left: 20px;">
            <div class="grid" style="margin-top: 20px;">
                <label style="width: 280px;">Select Stage</label>
                <p-dropdown [options]="bulkEditStages" [(ngModel)]="selBulkEditStage" />
            </div>
            <div class="grid" style="margin-top: 20px;">
                <p-checkbox [(ngModel)]="editCommentsChecked" [binary]="true" inputId="editComments" [style]="{'margin-bottom': '10px','margin-right': '10px'}"/>
                <label for="editComments" style="width: 250px;">Change Comments</label>
                <input type="text" style="width: 450px;" pInputText [(ngModel)]="bulkComments" />
            </div>
            <div class="grid" style="margin-top: 20px;">
                <p-checkbox [(ngModel)]="editMetadataChecked" [binary]="true" inputId="editMetadata" [style]="{'margin-bottom': '10px','margin-right': '10px'}"/>
                <label for="editMetadata" style="width: 250px;">Change MetaData</label>
                <p-multiSelect [options]="metaDataList" [(ngModel)]="bulkMetaData" placeholder="Select" [resetFilterOnHide]="true" [style]="{'width': '450px'}" />
            </div>
            <div class="grid" style="margin-top: 20px;">
                <p-checkbox [(ngModel)]="editCusMetadataChecked" [binary]="true" inputId="editCusMetadata" [style]="{'margin-bottom': '10px','margin-right': '10px'}"/>
                <label for="editCusMetadata" style="width: 250px;">Change Custom MetaData</label>
                <input type="text" style="width: 450px;" pInputText [(ngModel)]="bulkCusMetaData" />
            </div>  
        </div>
    </p-dialog>
</p-panel>
<p-dialog [(visible)]="showSaveModal" [modal]="true" [contentStyle]="{'overflow':'visible'}" [draggable]="false" [baseZIndex]="10000" [dismissableMask]="true" styleClass="headerPanelMedium">
	<p-header class="grid" style="width: 570px;">
        <label style="margin-top: 10px;">Save Session</label>
        <button pButton type="button" (click)="saveSession()" style="width: 70px; margin-left: 70%; margin-top: 5px;" [disabled]="!sessionName">Save</button>
	</p-header>
    <div> 
        <label style="margin-left: -5px;">
            Please enter a unique name for session to avoid overwriting:
        </label>  
        <input style="width: 150px;" pInputText type="text" [(ngModel)]="sessionName"/>   
    </div>
</p-dialog>
<p-dialog  [modal]="true" [(visible)]="showPStageModal" [closable]="false" [draggable]="false" [baseZIndex]="10000" [style]="{  'width': '65%','height': '70vh' }" appendTo="body">
    <ng-template pTemplate="header">
        <div style="display: flex;justify-content: space-between; width: 100%;">
            <h6 style="margin: 0;">Select and Apply PStage</h6>
            <div>                
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" (onClick)="addPStageRow()" title="Add Row"> Add Row</p-button>
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(pStageData.length > 0 && !allowPStageSettingSave)" (onClick)="savePStageData(true)" title="Add and Close"> Add & Close </p-button>
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" (onClick)="savePStageData(false)" title="Close"> Close </p-button>
            </div>
        </div>
    </ng-template>
    <div style="display: flex;justify-content: flex-end;">
    </div>
    <div>
        <ag-grid-angular *ngIf="pStageData.length > 0"
        style="height: 55vh;margin-left: 45px;;margin-top: 5px; width: 90%;" 
        class="ag-theme-balham"
        [rowData]="pStageData" 
        [columnDefs]="pStageColDef"
        [defaultColDef]="defaultPStageColDef" 
        rowSelection="multiple"
        [stopEditingWhenCellsLoseFocus]="true"
        [enableBrowserTooltips]="true"
        [gridOptions]="gridOptionsPStage"
        (gridReady)="onPStageGridReady($event)"></ag-grid-angular>
    </div>
</p-dialog>
<p-dialog [modal]="true" [(visible)]="showAPEXJobs" [draggable]="false" [baseZIndex]="10000" [style]="{  'width': '1260px','height': '70vh' }" appendTo="body">
    <ng-template pTemplate="header">
        <div style="display: flex;justify-content: space-between; width: 96%;">
            <h6 style="margin: 0;">Select APEX Session</h6>
            <p-button data-toggle="tooltip" data-placement="top" [disabled]="!apexGridApi || apexGridApi?.getSelectedRows()?.length === 0" (onClick)="submitAPEXData()" title="Submit GE(s) to APEX">Submit</p-button>
        </div>
    </ng-template>
    <ag-grid-angular *ngIf="apexData.length > 0"
        style="height: 100%;" 
        class="ag-theme-balham"
        [rowData]="apexData" 
        [columnDefs]="apexColDef"
        [defaultColDef]="defaultPStageColDef" 
        rowSelection="single"
        [statusBar]="apexStatusBar"
        (gridReady)="onApexGridReady($event)">
    </ag-grid-angular>
</p-dialog>
<p-confirmDialog [style]="{'width' : '550px', 'height' : '230px'}" appendTo="body">  
</p-confirmDialog> 
