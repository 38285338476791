import { Crop } from "../models/crop";
import { MasterData } from "../models/masterData";
import { MetaData } from "../models/metaData";
import { PreCodePreferences } from "../models/preCodePreferences";
import { UserClaim } from "../models/userclaim";

export class StaticFields {
  static codingPageData: any = null;
  static lineGraphPageData: any = null;
  static getYears(): string[] {
    const curYear = (new Date()).getFullYear();
    const years: string[] = [];
    years.push((curYear + 1).toString());
    years.push(curYear.toString());
    years.push((curYear - 1).toString());
    years.push((curYear - 2).toString());
    years.push((curYear - 3).toString());
    years.push((curYear - 4).toString());
    return years;
  }   
  
  static userInfo: any;
  static homeScreenData: any = null;
  static userDetail: any;
  static appUserId: any;
  static precodeScreenData: any;
  static crops: Crop[];
  static precodePreference: PreCodePreferences =  null;
  static masterData: MasterData [] =  null;
  static metaData: MetaData [] =  null;
  static years: string[] = this.getYears();
  static userClaim: UserClaim = new UserClaim();
  static materialTypes : string[] = [
    "Unknown",
    "A Line",
    "B Line", 
    "R Line",
    "Conventional Nursery",
    "Doubled Haploid NPL",
    "Experimental",
    "Germplasm",
    "Hybrid",
    "Inbred" ,
    "Mutation Breeding",
    "Named Material",
    "Open Pollinated",
    "Plant Introduction",
    "Population", 
    "Product", 
    "Recurrent Selection",
    "Synthetic",
    "Topcross",
    "Transgenic Nursery",
    "Variety",
    "Single Cross",
    "T0",
    "Clones"
  ];
}