import { Component } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { MetaData } from 'src/app/models/metaData';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { AppConstants, DataType } from 'src/app/shared/appconstants';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  showGrid: boolean = false;
  gridApi: any;
  selType: any;
  rowData: any[] = [];
  rowDataCopy: any[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true,
    editable: true,
    width: 70,
    minWidth: 20
  };
  public columnDefs: ColDef[];
  public columnDefsStageData: ColDef[] = [
    { field: 'crop', headerName: 'Crop', cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => { return { values: this.crops, valueListMaxHeight: 250 } }},
    { field: 'isEntry', headerName: 'Entry PStage', cellEditor: 'agCheckboxCellEditor'},
    { field: 'stage',headerName: 'PStage Values' },    
    { field: 'ranking', headerName: 'PStage Ranking' },   
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor'}
  ];
  public columnDefsMetaData: ColDef[] = [
    { field: 'name',headerName: 'MetaData', cellEditor: 'agTextCellEditor' },
    // { field: 'type', headerName: 'Type', cellEditor: 'agSelectCellEditor',
    //   cellEditorParams: () => { return { values: AppConstants.MetaDataTypes, valueListMaxHeight: 250 }}},  
    { field: 'crop', headerName: 'Crop', cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => { return { values: ["ALL", ...this.crops], valueListMaxHeight: 250 } }},  
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor'}
  ];

  get crops(): string [] {
    return StaticFields.crops.filter(ele => ele.isActive).map((d: any) =>  d.Name);
  }

  get dataTypes(): any [] {
    return AppConstants.DataTypes;
  }
  
  get SelType(): any {
    return this.selType;
  }
  set SelType(type) {
    this.selType = type;
    if(type != undefined) {
      if (type.name == DataType.MetaData) {
        this.columnDefs = this.columnDefsMetaData;     
        this.crops.unshift("All");  
        this.getMetaData();
      } else {
        this.columnDefs = this.columnDefsStageData;
        this.crops.splice(this.crops.indexOf("All"), 1);  
        this.getMasterData();
      }
    }
  }

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {     
  }

  get getSelectedRows(): any {
    return this.gridApi ? this.gridApi.getSelectedRows() : null;
  }

  get updatedData(): any {
    return this.rowData.filter(d => d.hasChanges);
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }

  onCellValueChanged(item: any) {
    if(this.selType.name == DataType.MetaData) {
       if (item.column.colId === 'name' || item.column.colId === 'crop' || item.column.colId === 'type') {
        const foundItems = this.rowData.filter(d => d.name === item.data.name && d.crop === item.data.crop && d.type === item.data.type);
        if (foundItems.length > 1) {
          this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "Duplicate metadata, crop and type combination found." });
          item.data[item.column.colId] = item.newValue = item.oldValue;
          this.gridApi.refreshCells(this.getSelectedRows);
          return;
        }
      }
    }
    else {
      if (item.column.colId === 'ranking' && item.newValue < 1) {   
         this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "PStage Ranking " + item.data.ranking + " Not Valid, Ranking Can not be Negative" });
         item.data[item.column.colId] = item.newValue = item.oldValue;     
         this.gridApi.refreshCells(this.getSelectedRows);   
         return;
      }
      if (item.column.colId === 'crop' || item.column.colId === 'isEntry' || item.column.colId === 'ranking') {
        var cropEntryGrouped = CommonService.groupBy(this.rowData.filter(p => p.isEntry == true), function(row: any ){
          return [row.crop];
        });       

        if(cropEntryGrouped.filter(p => p.length > 1).length > 0) {
          this.rowData.filter(p => p.isEntry == true && p.crop == item.data.crop && p.id !== item.data.id).forEach(p => { p.isEntry = false; p.hasChanges = true });
          this.gridApi.refreshCells(this.getSelectedRows);          
        }

        var cropRankingGrouped = CommonService.groupBy(this.rowData, function(row: any ){
          return [row.crop, row.ranking];
        });
       
        // if (item.column.colId === 'crop') {     
        //   let data = this.rowData.filter(p => p.type == 'Stage' && p.crop == item.data.crop);
        //   let ranking = data.length > 1 ? Math.max(...data.map(p => p.ranking)) + 1: 1;
        //   item.data['ranking'] = ranking;         
        //   this.gridApi.refreshCells(this.getSelectedRows);   
        // }

        if(cropRankingGrouped.filter(p => p.length > 1).length > 0) {
          if (item.column.colId !== 'crop') {             
            this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "PStage Ranking " + item.data.ranking + " Not Valid, It Must be Unique for " + item.data.crop });
            item.data[item.column.colId] = item.newValue = item.oldValue;     
            this.gridApi.refreshCells(this.getSelectedRows);   
            return;
          }           
        }    
      }
    }
    if (item.data.id && item.data.hasChanges) {
      delete item.data.hasChanges;
      const existingVal = this.rowDataCopy.find(d => d.id === item.data.id);
      item.data.hasChanges = JSON.stringify(existingVal) !== JSON.stringify(item.data);
    } else
      item.data.hasChanges = true;
  }  

  addRow() {
    if (this.selType.name == 'Meta Data') {
      this.addMetaDataRow();
    } else {
      this.addStageRow();
    }  
  }

  addStageRow() {
    if (this.updatedData.filter((d: any) => !d.id && !(d.crop && d.stage)).length > 0) {
      this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "You can't add a new row till all values have been provided for the last one." });
      return;
    }
    const newRow = { type: 'Stage', crop: '', isEntry : false, stage : '', ranking : 1, isActive: true, hasChanges: true }
    this.rowData.unshift(newRow);
    this.gridApi.setGridOption('rowData', this.rowData);
  }

  addMetaDataRow() {
    if (this.updatedData.filter((d: any) => !d.id && !(d.name && d.type && d.crop)).length > 0) {
      this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "You can't add a new row till all values have been provided for the last one." });
      return;
    }
    const newRow = { name: '', type : '', crop: '', isActive : true, hasChanges: true }
    this.rowData.unshift(newRow);
    this.gridApi.setGridOption('rowData', this.rowData);
  }

  saveChanges() {
    if(this.selType.name == DataType.Stage) {
      var cropEntryGrouped = CommonService.groupBy(this.rowData.filter(p => p.isEntry == true), function(row: any ){
        return [row.crop];
      });       

      var cropEntry = cropEntryGrouped.filter(p => p.length > 1);
      if(cropEntry.length > 0) {
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "Only Single PStage Entry Selction Allowed for a Crop " + cropEntry[0][0].crop});
        return;   
      }

      var cropPStageGrouped = CommonService.groupBy(this.rowData, function(row: any ){
        return [row.crop, row.stage];
      });  

      var cropPStage = cropPStageGrouped.filter(p => p.length > 1);
      if(cropPStage.length > 0) {              
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "PStage Values Must be Unique for a Crop " + cropPStage[0][0].crop });
        return;
      }

      var cropRankingGrouped = CommonService.groupBy(this.rowData, function(row: any ) {
        return [row.crop, row.ranking];
      });  

      var cropRanking = cropRankingGrouped.filter(p => p.length > 1);
      if(cropRanking.length > 0) {              
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "PStage Ranking Must be Unique for a Crop " + cropRanking[0][0].crop });
        return;
      }
    }
    const validData = this.updatedData.filter((d: any) => this.selType.name == DataType.MetaData ? d.name && d.type && d.crop : d.crop && d.stage);
    if (this.updatedData.length > validData.length) {
      if (validData.length === 0) {
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Invalid Rows', detail: `${this.updatedData.length} row(s) with incomplete data found, can't be updated.` });
        return;
      }
      this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `${this.updatedData.length - validData.length} row(s) with incomplete data found. Do you want to submit remaining ${validData.length} row(s).`,      
      accept: () => {
        this.submitData(validData);
      }
      });
    } else
      this.submitData(validData);
  }

  submitData(validData: any) {
    this.apiService.putData(this.selType.name == DataType.MetaData ? "SubmitMetaData" : "SubmitMasterData", validData).subscribe((result : any) => {
      if (result.httpStatusCode == 200) {    
        this.rowData = result.data;
        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
        if (this.selType.name == DataType.MetaData) {
          StaticFields.metaData = this.rowData;
        }
        else {
          StaticFields.masterData = this.rowData;
        }
       } 
    });
  }

  getMasterData() {
    this.rowData = StaticFields.masterData;
    this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
    this.showGrid = true;   
  }

  getMetaData() {
    this.rowData = StaticFields.metaData;
    this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
    this.showGrid = true;
  }

  reset() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel all the changes?',      
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(this.rowDataCopy));
        this.gridApi.setGridOption('rowData', this.rowData);
      }
    }); 
  }
}