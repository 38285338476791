import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CustomMessageService } from './custom.message.service';
import { HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { StaticFields } from '../shared/staticFields';

@Injectable({
  providedIn: 'root',
})
export class CommonService { 
 
  constructor(private apiService: ApiService, public customMessageService: CustomMessageService) {} 

   downloadExcelFile(agGrid?: AgGridAngular, selected? : boolean, exportTemplate? : boolean, isExcel_CODE : boolean = false){  
    this.apiService.downloadExcelWithMipLabels(agGrid, selected, exportTemplate, isExcel_CODE).subscribe((response: any) => {
      if (response) { 
        const blob = new Blob([response], { type: 'application/ms-excel' });
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);     
        let fileName = "PCM_" + this.customMessageService.getDateString(new Date()) + ".xlsx";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }, 100);
      }
    });
  }
  
  static groupBy(array: any[] , f: any )
  {
    var groups: any = {};
    array.forEach( function( o )
    {
      var group: any = JSON.stringify( f(o) );
      groups[group] = groups[group] || [];
      groups[group].push( o );  
    });
    return Object.keys(groups).map( function( group )
    {
      return groups[group]; 
    })
  }
}