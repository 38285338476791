export class AppConstants {
  public static get PRECODEMANAGER_View(): string { return "343bfa87-d763-4ea0-abe5-b452efd9029e"; }
  public static get PRECODEMANAGER_Admin(): string { return "efacfbaa-6930-42a5-a97b-45b7ad08db68"; }
  public static get PRECODEMANAGER_User(): string { return "a2fab7a0-9cfb-4671-bedb-381be6ce400c"; }
  public static get TOKEN(): string { return "msal.idtoken"; }
  public static get AUTHORIZATION(): string { return "Authorization"; }
  public static get BEARER(): string { return "Bearer "; }
  public static get NAVHOME(): string { return './home'; }
  public static get NAVAUTHORIZATION(): string { return './authorization'; }
  public static get UIUSERROLE(): string { return "uiUserRole "; }
  //public static get LOGIN(): string { return '/login'; }
  //public static get NAVLOGIN(): string { return './login'; }
  public static get DATAFIELD(): string { return 'dataField'; }
  public static get SORTORDER(): string { return 'sortOrder'; }
  public static get DESC(): string { return 'desc'; }
  public static get ASC(): string { return 'asc'; }
  public static get FILEEXT(): string { return '.xlsx'; }
  public static get CONTENTTYPE(): string { return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';}
  public static get URL(): string { return "/" ;}
  public static get Nav_Home(): string {  return "/home"; }
  public static get DataTypes(): any [] {  return [{name: DataType.Stage}, {name: DataType.MetaData}]; }
  public static get MetaDataTypes(): any [] {  return ["All", "GE", "SI"]; }
  public static get ImportFileHeaders(): string [] {  return [ImportFileHeader.geId,ImportFileHeader.geName, ImportFileHeader.program, ImportFileHeader.year, ImportFileHeader.stage, ImportFileHeader.metaData, ImportFileHeader.customMetaData, ImportFileHeader.programComment]; }
}

export class DataType {
  static Stage: string = "PStage";   
  static MetaData: string = "Meta Data";
}

export class ImportFileHeader {
  static geId: string = "GE ID";
  static geName: string = "GE Name";
  static program: string = "Program";
  static year: string = "Year";
  static stage: string = "PStage";
  static metaData: string = "Meta Data";
  static customMetaData: string = "Custom Meta Data";
  static programComment: string = "Program Comment";
}