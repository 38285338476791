import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { GridPerfComponent } from './components/gridPerf/gridPerf.component';
import { AdminComponent } from './components/admin/admin.component';
import { ManageprogramComponent } from './components/manageprogram/manageprogram.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { UnderconstructionComponent } from './components/underconstruction/underconstruction.component';
import { ImportFileComponent } from './components/import-file/import-file.component';
import { CodeComponent } from './components/code/code.component';
import { LinegraphComponent } from './components/linegraph/linegraph.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [ MsalGuard ] },
  { path: 'gridperf', component: GridPerfComponent, canActivate: [ MsalGuard ] },
  { path: 'admin', component: AdminComponent, canActivate: [ MsalGuard ] },
  { path: 'manageprecode', component: ManageprogramComponent, canActivate: [ MsalGuard ] },
  { path: 'preferences', component: PreferencesComponent, canActivate: [ MsalGuard ] },
  { path: 'importfile', component: ImportFileComponent, canActivate: [ MsalGuard ] },
  { path: 'code', component: CodeComponent, canActivate: [ MsalGuard ] },
  { path: 'underconstruction', component: UnderconstructionComponent, canActivate: [ MsalGuard ] },
  { path: '403', component: UnAuthorizedComponent,title: 'UnAuthorized'},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home ', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
