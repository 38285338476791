
<div class="grid" style="margin-top: -5px;">
  <div style="width: 225px; margin-right: 20px;" *ngIf="searchType === 'Search By Id or Name'">
      <p-card>           
          <div class="row">
            <div>
                <label style="font-weight: bold; display: block;">
                Search Assessment:
                </label>
                <p-radioButton 
                value="By Name" 
                [(ngModel)]="searchBy" label="By Name" (onClick)="setSearch($event)"
                 />
                 <p-radioButton 
                value="By ID"   label="By ID" [style]="{'margin-left': '5px'}"
                [(ngModel)]="searchBy"  (onClick)="setSearch($event)"
                 />
                <label  style="font-weight: bold; display: block; margin-bottom: 22px;">
                Total Rows : {{idList.length}}
                </label>
                <span>
                <textarea style="max-height: 244px;" [rows]="10" [cols]="20" [autoResize]="true" [(ngModel)]="idListText" pInputTextarea></textarea>
                </span>
            </div>
            <!-- <div class="col-xxl-5 col-xl-5 col-lg-5" style="display: inline-block;">
              <label style="font-weight: bold;">
              IDs not found:
              <a (click)="copyIdsList()" class="fake-link" style="display: block; color: #007bff;cursor: pointer;">Copy List</a>
              </label>
              <label  style="font-weight: bold; display: block;">
              Total Rows : {{getDuplicateIdCount}}
              </label>
              <label style="font-weight: bold; margin-bottom : 5px">
              Number of duplicates : {{idDuplicateCount}}
              </label>
              <span style="display: block;">
              <textarea style="max-height: 244px;" [(ngModel)]="idNotFoundList" [style]="{'margin-bottom' : '93px'}" [rows]="6" [cols]="15" [autoResize]="true" pInputTextarea></textarea>
              </span>
            </div> -->
          </div>
      </p-card>
  </div>
  <div [style.width]="searchType === 'Search By Id or Name' ? 'calc(100% - 255px)' : '100%'" *ngIf="rowData.length > 0">
      <span style="margin: 5px;padding-left: 5px;">
        <p-checkbox 
          [(ngModel)]="showSelected" 
          [binary]="true" 
          label="Show Selected"
          (onChange)="changeListView($event)"
          [style]="{'margin-bottom': '10px'}"
          inputId="binary" />

        <!-- <label style="font-weight: bold;display: block;">Select from list : </label> -->
      </span>
      <div class="row">
          <div> 
              <ag-grid-angular *ngIf="rowData.length > 0" id="agGrid"
                style="height: 70vh; width: 100%;"
                class="ag-theme-balham"  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [animateRows]="true" [rowData]="rowData" 
                [components]="components" [rowDragManaged]="true" [rowDragEntireRow]="true" [gridOptions]="gridOptions"  (cellEditingStarted)="startCellEditing($event)"  (cellEditingStopped)="stopEditing($event)"
                (rowSelected)="onRowSelected($event)" [suppressRowClickSelection] = "true"  [statusBar]="statusBar"  (gridReady)="onGridReady($event)"                      
                [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [animateRows]="true"></ag-grid-angular>
            </div>
      </div>
    </div>
</div>
<p-confirmDialog key="findModalExp"></p-confirmDialog>

  
