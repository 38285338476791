import { Component, Input, OnInit } from '@angular/core';

@Component({
    template: `
        <span #dynamicInsert>
            <span>
                <p-checkbox [binary]="true" [(ngModel)]="context.onlyActiveTraits" inputId="iaActive" [style]="{'margin-bottom': '10px','margin-right': '3px'}" />
                <label for="iaActive">Is Active</label>
                <p-radioButton value="Get All Assessments" [(ngModel)]="context.searchType" label="Get All Assessments" (onClick)="context.setSearchType('Get All Assessments')" [style]="{'margin-bottom': '10px','margin-left':'10px'}"/>
                <p-radioButton value="Search By Id or Name" label="Search By Id or Name" [style]="{'margin-left': '5px'}" (onClick)="context.setSearchType('Search By Id or Name')" [(ngModel)]="context.searchType" [style]="{'margin-bottom': '10px', 'margin-left': '5px',}" />
            </span>
            <span style="margin-left: 15px;">
                <label for="crop">Crop*: </label>
                <p-dropdown [disabled]="context.disableCrop" [options]="context.crops" [(ngModel)]="context.selectedCrop" name="Crop" optionLabel="Name" ></p-dropdown>            
                <p-button  id="buttonStyle" (click)="context.search()" [disabled]="context.searchType === 'Search By Id or Name' && context.idList.length === 0" >Search</p-button>
                <p-button  id="buttonStyle" [disabled]="context.selectedAssessments === 0" (click)="context.addAssessments(false)" 
                data-toggle="tooltip" data-placement="top" title="Add Assessments">Add</p-button>
                <p-button  id="buttonStyle" [disabled]="context.selectedAssessments === 0"  (click)="context.addAssessments(true)" 
                data-toggle="tooltip" data-placement="top" title="Add Assessments and CLose">Add & Close</p-button>
                <p-button  id="buttonStyle" (click)="context.reset()" [disabled]="context.searchType !== 'Search By Id or Name' && context.rowData.length === 0"
                data-toggle="tooltip" data-placement="top" title="Set page back to default">Reset</p-button>   
                <p-button  id="buttonStyle" (click)="context.closeDialog()" 
                data-toggle="tooltip" data-placement="top" title="Close">Close</p-button>
            </span>
        </span>
`,
styles:[
   `#buttonStyle {
        margin-left : 5px;
        
    }

    :host ::ng-deep .p-radiobutton-label {
        margin-bottom: 10px;
    }
    `
]
})
export class SearchGeAssessmentsHeader implements OnInit {
    @Input('incomingData') incomingData: any;
    context : any;
    constructor() {}
    ngOnInit() {
        this.context = Object.getPrototypeOf(this.incomingData);
    }
}