<div *ngIf="!report" class="grid">
    <div style="width: 260px;">
        <div style="margin-left: 10px;display: block;">
            <p-floatLabel style="margin-left: 20px;">
                <p-dropdown [options]="crops" [(ngModel)]="selectedCrop" [filter]="true" optionLabel="Name" placeholder="Select" />
                <label style="font-size: large;">Crop </label>
            </p-floatLabel>
        </div>
        <div style="margin-left: 10px;display: block;margin-top: 15px;">
            <label style="font-weight: bold;font-size: large;vertical-align: middle;"> Search By:</label>
            <p-radioButton value="CVN" [(ngModel)]="searchBy" label="CVN" (onClick)="setSearch($event)" />
            <p-radioButton value="GE ID" label="GE ID" [style]="{'margin-left': '5px'}" [(ngModel)]="searchBy" (onClick)="setSearch($event)" />
            <label style="font-weight: bold; display: block; margin-bottom: 12px;margin-top: 5px;">Total Rows :
                {{idList.length}}</label>
            <span>
                <textarea style="max-height: 244px;" [rows]="10" [cols]="20" [autoResize]="true" [(ngModel)]="idListText" pInputTextarea></textarea>
            </span>
        </div>
    </div>
   <div class="col">
        <ag-grid-angular *ngIf="rowData.length > 0" id="agGrid"
        style="height: 60vh; width: 100%;"  class="ag-theme-balham"  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" 
        [animateRows]="true" [rowData]="rowData" [rowDragManaged]="true" [rowDragEntireRow]="true"
        [gridOptions]="gridOptions" [suppressRowClickSelection] = "true"  [statusBar]="statusBar" (gridReady)="onGridReady($event)"                      
        [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [animateRows]="true"></ag-grid-angular>
   </div>
</div>
<div *ngIf="report" >
    <ag-grid-angular *ngIf="rowData.length > 0" id="agGrid"
     style="height: 60vh; width: 100%;"  class="ag-theme-balham"  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" 
     [animateRows]="true" [rowData]="rowData" [rowDragManaged]="true" [rowDragEntireRow]="true"
     [gridOptions]="gridOptions" [suppressRowClickSelection] = "true"  [statusBar]="statusBar" (gridReady)="onGridReady($event)"                      
    [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [animateRows]="true"></ag-grid-angular> 
</div>
 
  
    
  