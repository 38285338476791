import { EditorModule} from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {InputSwitchModule} from 'primeng/inputswitch';
import { AutoCompleteModule} from 'primeng/autocomplete';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {TableModule } from 'primeng/table';
import {DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import {TabMenuModule} from 'primeng/tabmenu';
import { MessageService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {ConfirmationService} from 'primeng/api';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ChipsModule} from 'primeng/chips';
import {CardModule} from 'primeng/card';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputNumberModule } from 'primeng/inputnumber';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ContextMenuModule } from 'primeng/contextmenu';

export const PrimeNgProviders = [
        MessageService,
        DialogService,
        ConfirmationService
]

export const PrimeNgModule = [
        InputNumberModule,
        FloatLabelModule,
        ContextMenuModule,
        ButtonModule,
        DropdownModule,
        MultiSelectModule,
        EditorModule,
        PanelModule,
        InputSwitchModule,
        AutoCompleteModule,
        TabMenuModule,
        ListboxModule,
        CheckboxModule,
        DialogModule,
        TableModule,
        ToastModule,
        CalendarModule,
        RadioButtonModule,
        OverlayPanelModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        InputTextareaModule,
        ChipsModule,
        CardModule,
        ColorPickerModule
]