import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { SettingService } from './setting.service';
import { Settings } from '../models/settings';
import { LoadingService } from './loading.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CustomMessageService } from './custom.message.service';
import { AppConstants } from '../shared/appconstants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(public http: HttpClient, public loader: LoadingService, public customMessageService: CustomMessageService, @Inject(SettingService) public settings: Settings) {}
 
  getData(methodName: string, httpParams: HttpParams = new HttpParams(), showLoading: boolean = true): any {
    if (showLoading) { this.loader.setMessage('Loading...'); }
     const webApiUrl = `${this.settings.webAppApiUrl}${methodName}`;
     return this.http.get(webApiUrl, { params: httpParams}).pipe(   
      map((response: any) => {      
        return this.responseHandler(response, false);
      }), 
      catchError((error) => {
        return this.errorHandler(error);
      }),
        finalize(() => {
          if (showLoading) { this.loader.clearMessage(); }
        })
     );
   }
  
   getOData(odataQuery: string, showLoading: boolean = true): any {
    if (showLoading) { this.loader.setMessage('Loading...'); }
    const webApiUrl = `${this.settings.webAppODataUrl}${odataQuery}`;
    return this.http.get(webApiUrl).pipe(   
      map((response: any) => {        
        return response;
      }), 
      catchError((error) => {
        return this.errorHandler(error);
      }),
        finalize(() => {
          if (showLoading) { this.loader.clearMessage(); }
        })
     );
   }

   getRADData(odataQuery: string, showLoading: boolean = true): any {
    if (showLoading) { this.loader.setMessage('Loading...'); }
     const webApiUrl = `${this.settings.rad.odataUrl}${odataQuery}`;
     return this.http.get(webApiUrl).pipe(   
      map((response: any) => {        
        return response;
      }), 
      catchError((error) => {
        return this.errorHandler(error);
      }),
        finalize(() => {
          if (showLoading) { this.loader.clearMessage(); }
        })
     );
   }
 
   postData(methodName: string, data: any, showSuccesMessage: boolean = true, showLoading: boolean = true) :  Observable<any>{
     if(showLoading) { this.loader.setMessage('Loading...'); }
     const webApiUrl = `${this.settings.webAppApiUrl}${methodName}`;
     return this.http.post(webApiUrl, data).pipe(   
      map((response: any) => {
        return this.responseHandler(response, showSuccesMessage);       
      }),
      catchError((error) => {
        return this.errorHandler(error);
      }),  
       finalize(() => {
        if (showLoading)
          this.loader.clearMessage();
       })
     );
   }   

   putData(methodName: string, data: any, showSuccesMessage: boolean = true, showLoading: boolean = true) {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const webApiUrl = `${this.settings.webAppApiUrl}${methodName}`;
    return this.http.put(webApiUrl, data).pipe(
      map((response: any) => {
        return this.responseHandler(response, showSuccesMessage);
      }),
      catchError((error) => {
        return this.errorHandler(error);
      }),
      finalize(() => {
        this.loader.clearMessage();
      })
    );
  }

   put(methodName: string, httpParams: HttpParams, showSuccesMessage: boolean = true, showLoading: boolean = true) {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const webApiUrl = `${this.settings.webAppApiUrl}${methodName}`;
    return this.http.put(webApiUrl, {}, {params : httpParams}).pipe(
      map((response: any) => {
        return this.responseHandler(response, showSuccesMessage);
      }),
      catchError((error) => {
        return this.errorHandler(error);
      }),
        finalize(() => {
        this.loader.clearMessage();
      })
    );
  }
  
  public downloadExcelWithMipLabels(agGrid?: AgGridAngular, selected? : boolean ,exportTemplate?: boolean, isExcel_CODE? : boolean) {
    this.loader.setMessage('Loading...');
    let data: any = []; 
    let columns: any = [];       
    if(!exportTemplate) {
      if(!selected) {
        agGrid.api.forEachNodeAfterFilterAndSort(function(node) {      
          if(node.data) 
            data.push(node.data);
        });   
      } else {
        let selectedRows =  agGrid.api.getSelectedNodes();
        selectedRows.forEach(node => {      
          if(node.data) 
            data.push(node.data);
        });   
      }
      
      agGrid.api.getAllDisplayedColumns().map(p => 
      { 
        let col = p.getColDef();    
        columns.push({ "headerName": col.headerName, "field": col.field ? col.field : 'field', "mappedField": col.valueSetter, "objectField": col.valueParser, "objectCondition": col.valueFormatter, "objectConditionalField": col.filterValueGetter, "arrayObjectEnableValue": col.enableValue, "cellEditor": col.cellEditor, "values": col.cellEditorParams?.values});      
      });
    }
    else {
      AppConstants.ImportFileHeaders.forEach(ele => {
        columns.push({ "headerName": ele, "field": 'ele'});      
      });
      columns.push({ "headerName": 'Crop', "field": 'Crop'});   
    }
   
    const agGridData = '{"Columns": ' + JSON.stringify(columns) + ', "Data": ' + JSON.stringify(data) + ', "isExcel_CODE": '+ isExcel_CODE +'}';   
    const webApiUrl = `${this.settings.webAppApiUrl}DownLoadExcelFile`;   
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(webApiUrl, agGridData, { headers: httpHeaders, responseType: 'arraybuffer' }).pipe(
      catchError((error) => {
        return this.errorHandler(error);
      }),
      finalize(() => {
        this.loader.clearMessage();
      })
    );
  }   

  responseHandler(response: any, showSuccesMessage: boolean): any {
    if (response.httpStatusCode == 200 && showSuccesMessage) {
      this.customMessageService.showMessage({'severity': 'success', summary: 'Success', detail: response.message });          
    }    
    else if(response.httpStatusCode != 200) {
      this.customMessageService.showMessage({'severity': 'error', summary: 'Failed', detail: response.message });
    }  
    return response;
  }

  errorHandler(httpErrorResponse: HttpErrorResponse) {
    let errorTitle = httpErrorResponse.error.errors ? JSON.stringify(httpErrorResponse.error.title) : httpErrorResponse.statusText;
    let errorMessage = httpErrorResponse.error.errors ? JSON.stringify(httpErrorResponse.error.errors) : httpErrorResponse.message;
    if(httpErrorResponse.status == 400) {
      this.customMessageService.showMessage({'severity': 'warn', summary: errorTitle, detail: errorMessage});
    }else{
      this.customMessageService.showMessage({'severity': 'error', summary: errorTitle, detail: errorMessage});
    }
    return throwError(() => new Error('The Error'));
  }  

  async getFilteredAddresses(filteredText: string, showLoading: boolean = true): Promise<any> {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${filteredText}&format=json&apiKey=6b0ea8fefcaa4e6b9f46772356d5fa80`);
    this.loader.clearMessage();
    return (await response.json()).results;    
   }    

   public getUserRole(groupId: string, member ?: boolean): Observable<any> {
    this.loader.setMessage('Loading...');
    const graphApiUrl = member ? `${this.settings.graphApi}?$filter=id eq '${groupId}'` : "https://graph.microsoft.com/v1.0/me";
    return this.http.get(graphApiUrl);
  }

  public getUserInfo(): any {
    let userInfourl = "https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName,displayName,mail";
    return this.http.get(userInfourl);
  }
}