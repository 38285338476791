
<p-panel [toggleable]="true" header="Precode Preferences" >
    <ng-template pTemplate="icons">
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]=" !allowReset" title="Save Preferences" (onClick)="savePreferences()"> Save</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Cancel Changes" [disabled]="!allowReset" (onClick)="resetPreferences()"> Reset</p-button>
    </ng-template>
    <div style="display: inline-block;width: 75%;">
        <div class="flex flex-row flex-wrap ml-2 mt-0.5  gap-6" style="padding-top: 27px;width: 100%;">
                <p-floatLabel>
                    <p-dropdown [options]="crops" [(ngModel)]="selectedCrop" (onChange)="changeCrop(false)" [filter]="true" optionLabel="Name" placeholder="Select" />
                    <label for="crop">Crop</label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-dropdown [options]="levels" [(ngModel)]="selectedLevel" (onChange)="changeLevel()" optionLabel="name" placeholder="Select" />
                    <label for="level">Level </label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-multiSelect [options]="inclusions" [(ngModel)]="selectedInclusions" placeholder="Select" optionLabel="code">
                        <ng-template let-include pTemplate="include">
                            <div data-toggle="tooltip" data-placement="top" title="{{include.desc}}" style="width: 100%;">{{ include.code }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    <label for="include">Include</label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-multiSelect [options]="stages" [(ngModel)]="selectedStages" placeholder="Select" />
                    <label for="stage">Stage</label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-multiSelect [options]="years" [(ngModel)]="selectedYears" placeholder="Select" />
                    <label for="years">Years</label>
                </p-floatLabel>
                <p-floatLabel>
                    <p-dropdown id="genToFetch" [options]="genList" [(ngModel)]="genToFetch" optionLabel="name" />
                    <label for="genToFetch" style="top: -1.5rem;width: 160px;">Generations To Summarize</label>
                </p-floatLabel> 
         </div> 
            <div style="margin-top: 15px;"> 
                 <label style="text-transform: capitalize;font-weight: 800;width: 100%;margin-left: 5px;">Expand By :</label>
                 <div class="flex flex-row flex-wrap ml-2 pt-2 pb-4 gap-6" >
                    <div>
                        <p-checkbox [(ngModel)]="geLineageChecked" label="GE Lineage" [binary]="true" inputId="binary" />
                    </div>
                    <div>
                        <p-checkbox [(ngModel)]="seedInventoryChecked" label="Seed Inventory" [binary]="true" inputId="binary" />
                    </div>
                    <div><p-checkbox [(ngModel)]="popRequestChecked" label="Pop Request" [binary]="true" inputId="binary" />
                    </div>
                    <div>
                        <p-checkbox [(ngModel)]="experimentsChecked" label="Experiments" [binary]="true" inputId="binary" />
                    </div>
                    <div>
                        <p-checkbox [(ngModel)]="apexVoteChecked" label="APEX Vote" [binary]="true" inputId="binary" />
                    </div>
                 </div>              

            </div>
        </div>

    <div *ngIf="programs.length > 0" style="display : inline-block;width: 18%; position: absolute;">
        <div>
            <p-checkbox [(ngModel)]="showSelFavorites" [binary]="true" inputId="selFavorites" (onChange)="changeFavoritesView($event)" [style]="{'margin-bottom': '2px', 'margin-right': '5px'}" />
            <label for="selFavorites">Show Selected</label>
        </div>
        <label style="left: 0.429rem;color: #666666;transition-duration: 0.2s;">My Favourite Programs ({{favouritePrograms.length}} / {{programs.length}})</label>
        <p-listbox 
        [options]="programsCopy" 
        optionLabel="Code"
        [(ngModel)]="favouritePrograms"
        [filter]="true" 
        [multiple]="true" 
        [style]="{ width: '15rem', position: 'absolute' }" 
        [listStyle]="{'max-height': '220px'}" />
   
    </div>
    
    <br>
    <div class="grid" style="margin-top: 130px;"> 
        <div class="col-6">
            <label style="font-weight: bold;font-size: large;margin-bottom:3px">Germplasm Assessments ({{selectedAssessments.length}}/{{geAssessmentsList.length}})</label>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Add Germplasm Assessments" [disabled]="selectedCrop == undefined" (onClick)="addGeAssessments()"> Add</p-button>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Remove Germplasm Assessments" [disabled]="selectedAssessments.length === 0" [style]="{'margin-left': '5px'}" (onClick)="removeGeAssessments()"> Remove</p-button>    
            <p-table [value]="geAssessmentsList"  selectionMode="multiple" 
            [(selection)]="selectedAssessments"  [scrollable]="true" 
            scrollHeight="350px" 
            dataKey="traitId"  [tableStyle]="{ 'width': '44rem', 'margin-top':'10px' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 2rem"><p-tableHeaderCheckbox /></th>
                        <th>Id</th>
                        <th>Assessment Name</th>
                        <th>Assessment Description</th>
                        <th>Source</th>
                        <th>Region(s)</th>
                        <th>Year(s)</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td (click)="onRowClick(rowData)">
                            <p-tableCheckbox [value]="rowData" />
                        </td>
                        <td>{{ rowData.traitId }}</td>
                        <td>{{ rowData.traitName }}</td>
                        <td>{{ rowData.traitDescription }}</td>
                        <td>{{ rowData?.source }}</td>
                        <td>{{ rowData?.aois }}</td>
                        <td>{{ rowData?.years }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>  

        <div class="col-6">
            <label style="font-weight: bold;font-size: large;margin-bottom:3px">PStage Settings({{selectedPStages.length}}/{{pStagesList.length}})</label>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Add PStages"  (onClick)="showPStageDialog()"> Add</p-button>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Remove PStages" [disabled]="selectedPStages.length === 0" [style]="{'margin-left': '5px'}" (onClick)="removePStage()"> Remove</p-button>    
            <p-table [value]="pStagesList"  selectionMode="multiple" 
            [(selection)]="selectedPStages" 
            dataKey="id"  [tableStyle]="{ 'width': '44rem', 'margin-top':'10px' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 2rem"><p-tableHeaderCheckbox /></th>
                        <th>Crop</th>
                        <th>Type</th>
                        <th>Name(s)</th>
                        <th>Year(s)</th>                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td (click)="onRowClick(rowData)">
                            <p-tableCheckbox [value]="rowData" />
                        </td>
                        <td>{{ rowData.crop }}</td>
                        <td>{{ rowData.type }}</td>
                        <td>{{ rowData.name }}</td>
                        <td>{{ rowData?.year }}</td>
                       
                    </tr>
                </ng-template>
            </p-table>
        </div>  
    </div>
</p-panel>
<p-dialog  [modal]="true" [(visible)]="showPStageModal" [draggable]="false" [closable]="true" [baseZIndex]="10000" [style]="{  'width': '65%','height': '70vh' }" appendTo="body">
    <ng-template pTemplate="header">
        <div style="display: flex;justify-content: space-between; width: 100%;">
            <h6 style="margin: 0;">Select and Apply PStage</h6>
            <div>                
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" (onClick)="addPStageRow()" title="Add Row"> Add Row</p-button>
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(pStageData.length > 0)" (onClick)="deletePStageRow()" title="Remove Row"> Remove Row</p-button>
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(pStageData.length > 0 && !allowPStageSettingSave)" (onClick)="savePStageData(true)" title="Add and Close"> Add & Close </p-button>
                <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" (onClick)="savePStageData(false)" title="Close"> Close </p-button>
            </div>
        </div>
    </ng-template>
    <div>
        <ag-grid-angular *ngIf="pStageData.length > 0"
        style="height: 55vh;margin-left: 45px;;margin-top: 5px; width: 90%;" 
        class="ag-theme-balham"
        [rowData]="pStageData" 
        [columnDefs]="pStageColDef"
        [defaultColDef]="defaultPStageColDef" 
        rowSelection="multiple"
        [stopEditingWhenCellsLoseFocus]="true"
        [enableBrowserTooltips]="true"
        [gridOptions]="gridOptionsPStage"
        (gridReady)="onPStageGridReady($event)"
       >
      </ag-grid-angular>
    </div>
</p-dialog>
<p-confirmDialog [style]="{'width' : '450px', 'height' : '230px'}" appendTo="body">   
</p-confirmDialog> 
