import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { MsalSettings } from './app/models/msalSettings';
import { SettingService } from './app/services/setting.service';
import "ag-grid-enterprise";
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-063443}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Pioneer_Hi-Bred_Intl_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{27}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_September_2025}____[v3]_[01]_MTc1Njc2NzYwMDAwMA==63e11e68d69287b34a91ac47a17194ac");
if (environment.production) {
  enableProdMode();
}

function msalLoggerCallback(logLevel: LogLevel, message: string) { console.log(`[MSAL][${LogLevel[logLevel]}]: ${message}`); }
fetch('/assets/settings.json')
  .then(response => response.json())
  .then((msalSettings: MsalSettings) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {provide: MSAL_INSTANCE, useValue: new PublicClientApplication({
      auth: msalSettings.msal.config.auth,
      cache: msalSettings.msal.config.cache,
      system: {
        loggerOptions: {
          loggerCallback: msalLoggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    })
  },
  {
    provide: MSAL_GUARD_CONFIG, useValue: { ...msalSettings.msal.guard }
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useValue: { protectedResourceMap: new Map(msalSettings.msal.interceptor.protectedResourceMap) }
  },
       {
      provide: SettingService, useValue: { ...msalSettings }
    }]).bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
