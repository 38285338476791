import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '../../services/api.service';
import { StaticFields } from '../../shared/staticFields';
import { CustomMessageService } from '../../services/custom.message.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hideEditorCtrl = false;
  homeScreenData = {   
    "appUserId" : "",
    "box1" : "",
    "box2" : "",
    "box3" : "",
    "box4" : "",
    "mappingModel" : ""
  };
  isAdmin = false;
  isPCMAdmin: boolean = false;
  isPCMUser: boolean = false;
  
  constructor( public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService, private msalService : MsalService) { }
 
  ngOnInit(): void {    
    this.isPCMAdmin = StaticFields.userClaim.isPCMAdmin;
    this.isPCMUser= StaticFields.userClaim.isPCMUser;
    
    if (StaticFields.appUserId) {
      StaticFields.homeScreenData.appUserId = StaticFields.appUserId;
      this.homeScreenData = StaticFields.homeScreenData;
    }
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          StaticFields.homeScreenData.appUserId = StaticFields.appUserId;
          this.homeScreenData = StaticFields.homeScreenData;
          clearInterval(interval);
        }       
      }, 600);
    }
  } 
  
  editContent() {
    this.hideEditorCtrl = true;
  }

  cancelEdit() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel the changes made?',
      accept: () => {
        this.hideEditorCtrl = false;
        this.homeScreenData = StaticFields.homeScreenData ? StaticFields.homeScreenData : this.homeScreenData;
          //Actual logic to perform a confirmation
      }
    });    
  }

  saveHomePageData() {   
    this.apiService.putData('SubmitHomePageData', this.homeScreenData).subscribe((result : any)  => {
      if (result.httpStatusCode == 200) {
        this.homeScreenData = StaticFields.homeScreenData = result.data[0];
        this.hideEditorCtrl = false;
      }     
    });
  }
}