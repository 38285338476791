import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, StatusPanelDef, GridReadyEvent, IRichCellEditorParams,GridOptions, IRowNode, RowSelectedEvent, CellClassRules, CellClassParams, ISelectCellEditorParams } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { AutoCompleteComponent } from 'src/app/shared/autoComplete';
import { SearchGeAssessmentsHeader } from 'src/app/shared/searchGeAssessmentsHeader';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-geassessmentmodal',
  templateUrl: './geassessmentmodal.component.html',
  styleUrl: './geassessmentmodal.component.scss'
})
export class GeassessmentmodalComponent implements OnInit {
  crops: any[] = [];
  selectedCrop : any;
  disableCrop : boolean = true;
  assessmentMembersList: any[]= [];
  assessmentMembers: any[] = [];
  searchType: string = "Get All Assessments";
  searchBy: string = "By Name";
  selectType: string = "Show all Assessments";
  showSelected : boolean = false
  selectedMembers: any;
  enableSearch : boolean = false;
  rowData : any[] =[];
  idList: string[] = [];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth : 50, 
    resizable: true,
  };
  public columnDefs: ColDef[] = [
    { field: 'traitId', headerName : 'Assessment Id', filter: 'agTextColumnFilter', checkboxSelection: true, headerCheckboxSelection : true, width : 165 },
    { field: 'traitName', headerName : 'Assessment Name', filter: 'agTextColumnFilter',width : 165 },
    { field: 'description', headerName : 'Assessment Description', filter: 'agTextColumnFilter', flex : 1 },
    { field: 'vvsType', headerName : 'Type', width : 110 },
    { field: 'source', headerName : 'Source', width : 110, editable: true,   cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Precode", "Parent", "Both"],
      } as ISelectCellEditorParams, },
    { field: 'aoiNameList',headerName : 'Region(s)', flex : 1, editable: function (params: any) {
      if (params.data?.aoiRequired) {
        return params.data?.aoiRequired
      }
      return false;
      },  cellEditor: 'primeAutoCompleteEditor',  valueGetter: params => {
        return params.data?.aoiNameList?.map((ele: any) => ele.name).join();
      },
      valueSetter: params => {     
          return true;
      },
      cellStyle: cellStyle,
    },
    { field: 'years',headerName : 'Year(s)', flex : 1,  cellEditor: 'agRichSelectCellEditor', editable: function (params: any) {
        if (params.data?.yearRequired) {
          return params.data?.yearRequired
        } else {
          return false;
        }
      }, cellEditorParams: {
        values: StaticFields.years,
        multiSelect: true,
        searchType: 'matchAny',
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams,
      cellStyle: yearCellStyle,

    }
  ];
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  gridOptions : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true
  }

  public components: {
    [p: string]: any;
  } = {
    primeAutoCompleteEditor: AutoCompleteComponent,
  };
  gridApi: any;
  selectedType: string;
  allGETraits: any;
  renderer: Renderer2;
  preSelectedAssessments: any[] = [];
  onlyActiveTraits: boolean = true;

  constructor(private apiService : ApiService,public config : DynamicDialogConfig, public ref : DynamicDialogRef,public customMessageService: CustomMessageService,
    private confirmationService : ConfirmationService,private readonly rendererFactory: RendererFactory2,
    private viewContainerRef: ViewContainerRef
  ) {
    this.renderer = rendererFactory.createRenderer(null,null);
    this.selectedType = 'Show all Assessments';
  }

  get selectedAssessments() : number {
    return this.gridApi && !this.gridApi.isDestroyed() && this.gridApi.getSelectedRows() ? this.gridApi.getSelectedRows().length : 0;
  }

  get idListText(): string {
    return this.idList.join("\r\n");
  }

  set idListText(value) {
    this.idList = value.split(/\r?\n/);
    if (this.idList.length < 2)
      this.idList = value.split(",");
    this.idList = this.idList.filter((v, i, a) => a.indexOf(v) === i);
    this.idList = this.idList.map(s => s.trim()).filter(s => s);
    // let invalidId = "";
    // for (let i = 0; i < this.idList.length; i++) {
    //   if (!this.validateNumber(this.idList[i])) {
    //     invalidId = this.idList[i];
    //     break;
    //   }
    // }
    // if (invalidId) {
    //   this.customMessageService.showMessage({severity:'warn', summary : 'Invalid Id', detail: invalidId + " is not a number. Please enter valid values."});
    //   this.idList.splice(0);
    // }
  }

  ngOnInit(): void {
    this.crops = this.config.data.crops;
    this.selectedCrop = this.config.data.selectedCrop;
    this.preSelectedAssessments = this.config.data?.selectedAssessmentsList ? this.config.data?.selectedAssessmentsList : [];
    setTimeout(() => {
      const componentRef = this.viewContainerRef.createComponent(SearchGeAssessmentsHeader);
      componentRef.instance.incomingData = Object.create(this);
      let titleSpan = document.getElementsByClassName('p-dialog-header-icons')[0];
      this.renderer.appendChild(titleSpan, componentRef.location.nativeElement);
      this.search();
    },300);
  }

  addAssessments(isClose : boolean) {
    if(this.checkValidAssessment().length > 0)  {
      this.customMessageService.showMessage({severity: "warn", detail : `Trait(s) ${this.checkValidAssessment().map((ele : any) => ele.traitName).join()} requires AOI(S) or year(s) fields to be filled`, summary:"Unable to add trait(s)" })
    } 
    else {
      let selectedNodes: any[] = [];
      this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
        if(rowNode.isSelected()){
          if(rowNode.data?.years)
            if(typeof(rowNode.data?.years) == "string") 
              rowNode.data['years'] = rowNode.data?.years;
             else
                rowNode.data['years'] = rowNode.data?.years.join();
          selectedNodes.push(rowNode.data);
        }
      })
      if(this.preSelectedAssessments?.length > 0)
        this.preSelectedAssessments = this.preSelectedAssessments.filter(ar => !selectedNodes.find(rm => (rm.traitId === ar.traitId)));               
      if (isClose) {
        this.ref.close({'assessmentMembers' : [...this.assessmentMembersList, ...selectedNodes, ...this.preSelectedAssessments ]});
      } else {
        this.assessmentMembersList = [...this.assessmentMembersList, ...selectedNodes, ...this.preSelectedAssessments ];
      }
      this.customMessageService.showMessage({severity: 'info', summary : 'Added', detail :"Assessment(s) have been added."});
    }
  }

  checkValidAssessment() : any[] {
    const selectedRows = this.gridApi.getSelectedRows();
    const invalidAssessment = selectedRows.filter((ele : any) => {
      let flag = false;
      if(ele?.aoiRequired) {
         ele.aoiNameList.length > 0 ? flag = false : flag = true;
      }
       if(ele?.yearRequired) {
         ele.years.length > 0 ? flag = false : flag = true;
      } 
      return flag;
    });
    return invalidAssessment;
  }

  closeDialog() {
    if (this.assessmentMembersList.length === 0 && this.rowData && this.rowData.length === 0) {
      this.confirmationService.confirm({
        header : 'Confirm',
        key : "findModalExp",
        message: 'Are you sure you want to leave without selecting atleast one member?',
        accept: () => {
          this.ref.close({'assessmentMembers': this.preSelectedAssessments});
        }
      });
    } else {
      let selectedNodes: any[] = [];
      this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
        if(rowNode.isSelected()){
          if(rowNode.data?.years){
            if(typeof(rowNode.data?.year) == "string")
              rowNode.data['years'] = rowNode.data?.years;
            else 
              rowNode.data['years'] = rowNode.data?.years.join();
          }
          selectedNodes.push(rowNode.data);
        }
      })
      if(this.assessmentMembersList.length === 0  && this.checkExistingTraits()) 
        this.ref.close({'assessmentMembers': selectedNodes});
       else if(this.assessmentMembersList.length === 0  && !this.checkExistingTraits()) {
        this.ref.close({'assessmentMembers': this.preSelectedAssessments});
       } else {
         this.ref.close({'assessmentMembers': this.assessmentMembersList});
       }
    }
  }

  checkExistingTraits() : boolean {
    if(this.preSelectedAssessments?.length > 0 && this.gridApi?.getSelectedRows()?.length > 0) {
      const existingTraitIds = this.preSelectedAssessments.map((ele: any) => ele.traitId).sort((a,b) => a-b);
      const selectedTraitIds = this.gridApi.getSelectedRows().map((ele : any) => ele.traitId).sort((a: any,b: any) => a-b);
      return JSON.stringify(existingTraitIds) === JSON.stringify(selectedTraitIds);
    } else {
      return false;
    }
  }

  setSearch(event : any) {
    if(this.idList.length > 0 && event.value) {
      this.idList = [];
      this.rowData = [];
    }
  }

  onRowSelected(event :RowSelectedEvent) {
    event.api.redrawRows();
  }

  reset() {
    this.searchType =  "Get All Assessments";
    this.searchBy = "By Name";
    this.selectType = "Show all Assessments"
    this.showSelected = false;
    this.rowData.splice(0);
    this.idList.splice(0);
  }

  setSearchType(newValue : string) {
    this.searchType = newValue;
    this.rowData.splice(0);
    const data = JSON.parse(JSON.stringify(this.allGETraits));
    const dataToShow = this.onlyActiveTraits ? data.filter((d: any) => d.status.toUpperCase() === 'ACTIVE') : data;
    if (newValue === 'Get All Assessments' && this.allGETraits) {
      this.rowData = [...dataToShow];
    } else if (this.idList.length > 0) {
      this.setGridDataByIdOrName(dataToShow);
    }
  }

  search() {
    if (!this.allGETraits || this.allGETraits.length === 0) {
      let params = new HttpParams();
      params = params.append('crop',this.selectedCrop.Description);
      this.apiService.getData('GetGEAssessments',params).subscribe( (result : any) => {
        if(result.data.length > 0) {
          const promise =  this.setInitialGridData(result.data);
          promise.then(() => {
            setTimeout(() => {
              this.gridApi.forEachNode((node : any) => {
                if (this.preSelectedAssessments?.find(ele => ele.traitId == node.data.traitId)) {
                  node.setSelected(true);
                }
              })
            },250)
           
          })
          
        } else {
          this.customMessageService.showMessage({severity: 'warn', summary:'No Data Found', detail:"Selected crop doesn't have any assessments associated"});
        }
      });
    } else {
      const data = JSON.parse(JSON.stringify(this.allGETraits));
      const dataToShow = this.onlyActiveTraits ? data.filter((d: any) => d.status.toUpperCase() === 'ACTIVE') : data;
      if (this.searchType == "Search By Id or Name") {
        this.setGridDataByIdOrName(dataToShow)
      } else{
        this.rowData = dataToShow;
      }
    }
  }

  setInitialGridData(data : any) : any{
    const promise =  new Promise((resolve) => {
      data.forEach((ele : any) => {
        const foundTrait = this.preSelectedAssessments?.find(e => e.traitId == ele.traitId)
        
        if (ele.traitGroups.findIndex((tg : any) => tg.groupName.toUpperCase().includes("YEAR")) > -1) {
          if (!ele.years) {
            ele.years = [];
          }
          ele["yearRequired"] = true;
          if(foundTrait?.years) {
            ele.years = foundTrait?.years.split(",");
          }
        }
        if (ele.traitGroups.findIndex((tg : any) => tg.groupName.toUpperCase().includes("AOI")) > -1){
          ele["aoiRequired"] = true;
          ele['aoiNameList'] = [];
        }
        if(foundTrait?.aois) {
          ele.aois = foundTrait?.aois;
          foundTrait.aois.split(",").forEach((p : any) => {
            ele['aoiNameList'].push({name: p, description : ""});
          })
        }
        ele['source'] = "Precode";
      });
      this.allGETraits = JSON.parse(JSON.stringify(data));
      const dataToShow = this.onlyActiveTraits ? data.filter((d: any) => d.status.toUpperCase() === 'ACTIVE') : data;
      if (this.searchType == "Search By Id or Name") {
        this.setGridDataByIdOrName(dataToShow)
      } else {
        this.rowData = dataToShow;
      }
      resolve(true);
    })
    return promise;
  }

  setGridDataByIdOrName(data : any) {
    if (this.searchBy == "By ID") {
      this.rowData = data.filter((ele : any) => this.idListText.indexOf(ele.traitId) !== -1)
    } else {
      const names = this.idListText.replace( /\n/g, " " ).replace(/\r/g, "" ).replace(',', " " ).split(" ").filter(n => n);
      this.rowData = data.filter((ele : any) => names.findIndex(str => ele.traitName.toLowerCase().includes(str.toLowerCase())) !== -1);
    }
    if(this.rowData.length === 0) {
      this.customMessageService.showMessage({severity: 'warn', summary:'No Data Found', detail:"Selected crop doesn't have any assessments associated"});

    }
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }

  startCellEditing(event : any) {    
    const row = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);
    if(row.data?.aoiRequired && event.colDef.field === "aoiNameList") {
      row.setRowHeight(75);
      this.gridApi.onRowHeightChanged();
    }
  }

  stopEditing(event : any) { 
    this.gridApi.resetRowHeights();
  }

  private validateNumber(strNumber: string): boolean {
    var regExp = new RegExp("^[0-9]*$");
    var isValid = regExp.test(strNumber);
    return isValid;
  }

  changeListView(event: any) {
    if (event.checked) {
      if (this.selectedAssessments > 0){
        let selectedNodes: any[] = [];
        this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: any, index: any) => {
          if(rowNode.isSelected()){
            if(rowNode.data?.years) {
            if(typeof(rowNode.data?.year) == "string")
              rowNode.data['years'] = rowNode.data?.years;
            else 
              rowNode.data['years'] = rowNode.data?.years.join();
            }
            selectedNodes.push(rowNode.data);
          }
        })
        this.rowData = [...selectedNodes];
        setTimeout(() => {
          this.gridApi.selectAll();      
        },15)
      } else if (this.selectedAssessments == 0) {
        this.customMessageService.showMessage({severity: 'warn', summary:'Nothing to display', detail:'Select atleast one assessment to display'});
        setTimeout(() => {
          this.showSelected = false;
        },15);
      }
    } else if(event.checked == false && this.selectedAssessments > 0) {
        const gridState = JSON.parse(JSON.stringify(this.rowData));
        const data = JSON.parse(JSON.stringify(this.allGETraits));
        const dataToShow = this.onlyActiveTraits ? data.filter((d: any) => d.status.toUpperCase() === 'ACTIVE') : data;
        if (this.searchType == "Search By Id or Name") {
          this.setGridDataByIdOrName(dataToShow)
        } else{
          this.rowData = [...dataToShow];
        }
        setTimeout(() => {
          this.gridApi?.forEachNode((node : any) => {
            if(gridState.find((ele: any) => ele.traitId === node.data.traitId)) {
              node.setSelected(true);
            }
          })
        },200)
    }
  }

}
function cellStyle(params: CellClassParams) {
  if(params.node.isSelected()) {
    if(params.data?.aoiRequired) {
      return {
        'border-style': 'solid',
        'border-color': 'red'
      } 
    } else {
      return null;
    }
  } else {
    return null;
  }
 
}

function yearCellStyle(params: CellClassParams) {
  if(params.node.isSelected()) {
    if(params.data?.yearRequired) {
      return {
        'border-style': 'solid',
        'border-color': 'red'
      } 
    } else {
      return null;
    }
  } else {
    return null;
  }
 
}