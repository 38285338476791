import { Component, Input, OnInit } from '@angular/core';

@Component({
    template: `
        <span #dynamicInsert>
            <p-button  id="buttonStyle" (click)="context.export()" 
            data-toggle="tooltip" data-placement="top" [disabled]="context.rowData.length === 0" title="Close">Export</p-button>
            <p-button *ngIf="!context.report" id="buttonStyle" data-toggle="tooltip" data-placement="top"  title="Select crop and enter CVN or Ge ID(s) to retrieve Data" [disabled]="!(context.idListText.length > 0)" (onClick)="context.retrieveData()"> Retrieve</p-button>
            <p-button *ngIf="!context.report" id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Reset Changes"  (onClick)="context.resetPreferences()"> Reset</p-button>
        </span>
`,
styles:[
   `#buttonStyle {
        margin-left : 5px;
        
    }

   
    `
]
})
export class ReportModalHeaderComponent implements OnInit {
    @Input('incomingData') incomingData: any;
    context : any;
    constructor() {}
    ngOnInit() {
        this.context = Object.getPrototypeOf(this.incomingData);
    }
}