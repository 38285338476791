import { Component, Input, OnInit } from '@angular/core';

@Component({
    template: `
        <span #dynamicInsert>    
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  title="Save Crop Preferences" [disabled]="context.allowReset" (onClick)="context.submitData()"> Save</p-button>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Reset Changes" [disabled]="context.allowReset"  (onClick)="context.reset()"> Reset</p-button>
        </span>
`,
styles:[
   `#buttonStyle {
        margin-left : 5px;
        
    }

   
    `
]
})
export class ManageCropModalHeaderComponent implements OnInit {
    @Input('incomingData') incomingData: any;
    context : any;
    constructor() {}
    ngOnInit() {
        this.context = Object.getPrototypeOf(this.incomingData);
    }
}