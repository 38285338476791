import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Component } from '@angular/core';

@Component({
    template: `
        <div class="grid" style="margin: 8px;">
            <div class="grid" style="margin: 1px;" data-toggle="tooltip" data-placement="top" title="Click to Open Color Selector">
                <label style="cursor: pointer;" (click)="openColorSelector()">
                    Set Color:  
                </label>
                <label [style.backgroundColor]="params.context.selColor === 'No Fill' ? '#ffffff' : params.context.selColor" style="margin-left: 4px; width: 25px; height: 25px; border-radius: 4px; display: block; border-style: solid; border-width: 1px; cursor: pointer;" (click)="openColorSelector()"></label>
            </div>
        </div>
    `,
})
export class SetCustomColorComponent implements IStatusPanelAngularComp {
    params!: IStatusPanelParams;
    selectedColor: string| undefined = "#ffffff";
    
    agInit(params: IStatusPanelParams): void {
        this.params = params;
    }

    openColorSelector() {
        this.params.context.openColorSelector();
    }
}